<template>
  <div class="row">
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
      mode="out-in"
      @after-enter="unblockCheckout"
    >
      <div
        class="cart-voucher-container cart-voucher-container--applied"
        v-if="isVoucherApplied"
        key="1"
      >
        <div class="small-9 column column--voucher-input">
          <span class="h--uppercase">{{ this.voucherApplied.code }}</span> code
          <button
            class="button--link button--voucher-remove"
            @click="emitClearVoucher"
          >
            Remove
          </button>
        </div>
        <div class="small-3 column column--voucher-apply column--voucher-value">
          <div v-if="loadingTotal">
            <svg
              width="30"
              height="12"
            >
              <use xlink:href="#loading_animation" />
            </svg>
          </div>
          <div v-else>
            -{{ getDiscountValueStr }}
          </div>
        </div>
      </div>
      <div
        class="cart-voucher-container"
        key="2"
        v-else
      >
        <form
          name="apply-voucher"
          @submit.prevent="discountCodeSubmit"
          v-if="addVoucherPressed"
        >
          <div
            class="small-9 column column--voucher-input"
            :class="{'error': showErrorMessage}"
          >
            <input
              type="text"
              name="voucher-code"
              id="voucher-code"
              :class="{'input--placeholder': inputAsPlaceholder}"
              v-model="discountCode"
              @keyup="discountCodeKeyup"
            >
            <label for="voucher-code">Gift card or discount code</label>
          </div>
          <div class="small-3 column column--voucher-apply">
            <button
              type="submit"
              class="button hollow button--full-width"
            >
              Apply
            </button>
          </div>
          <div
            class="small-12"
            v-if="showErrorMessage"
          >
            <p class="error-medium">
              {{ errorMsgDisplay }}
            </p>
          </div>
        </form>
        <div
          class="small-12 column cart__total-text"
          v-else
        >
          <button
            class="button--link"
            @click="emitAddDiscountVoucher"
          >
            + Add a gift card or discount code
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getCurrencyStr } from './../../../../../utils/currencyFormatting.js';

export default {
  name: 'DiscountVoucher',
  data () {
    return {
      recommendedVariant: null,
      hasAddedRecommendation: false,
      discountCode: '',
      discountCodeError: false,
      errorMsgNoCode: 'Please enter a code.',
    };
  },
  props: {
    addVoucherPressed: Boolean,
    voucherError: Boolean,
    voucherErrorMsg: {
      type: String,
      default: ''
    },
    voucherApplied: {
      type: Object,
      default: () => ({})
    },
    inputAsPlaceholder: Boolean,
    loadingTotal: Boolean,
  },
  computed: {
    isVoucherApplied() {
      return this.voucherApplied?.code && this.voucherApplied?.discountValue;
    },
    getDiscountValueStr() {
      return getCurrencyStr(this.voucherApplied.discountValue);
    },
    showErrorMessage() {
      return this.voucherError || this.discountCodeError;
    },
    errorMsgDisplay() {
      return this.voucherError ? this.voucherErrorMsg : this.errorMsgNoCode;
    }
  },
  methods: {
    emitAddDiscountVoucher() {
      this.$emit('addDiscountVoucher');
    },
    emitClearVoucher() {
      this.discountCode = '';
      this.$emit('clearVoucher');
    },
    discountCodeSubmit() {
      if (this.discountCode.length > 0) {
        this.$emit('setVoucher', this.discountCode);
      } else {
        this.discountCodeError = true;
      }
    },
    discountCodeKeyup($event) {
      if ($event.key !== 'Enter') {
        this.discountCodeError = false;
        if (this.voucherError) {
          this.$emit('resetVoucherError');
        }
        this.$emit('inputAsPlaceholderCheck', this.discountCode);
      }
    },
    unblockCheckout(el) {
      this.$emit('disableLoadingState');
    },
  }
};
</script>
