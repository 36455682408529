import { postTrackEvent } from '../utils/analytics';
import { isMobile } from '../utils/deviceHelpers';

const navTracking = () => {
  for (const item of document.querySelectorAll('.nav__item')) {
    item.addEventListener('click', (e) => {
      e.stopPropagation();
      postTrackEvent('nav_click_item', {
        position: 'header',
        name: e.target.innerHTML,
        link: e.target.href ? e.target.href : e.target.parentNode.href,
        type: isMobile() ? 'mobile' : 'desktop'
      });
    });
  }

  for (const item of document.querySelectorAll('.footer__link')) {
    item.addEventListener('click', (e) => {
      e.stopPropagation();
      postTrackEvent('nav_click_item', {
        position: 'footer',
        name: e.target.innerHTML,
        link: e.target.href ? e.target.href : e.target.parentNode.href,
        type: isMobile() ? 'mobile' : 'desktop'
      });
    });
  }

  const searchBtn = document.querySelector('.header-right .header__search');
  if (searchBtn) {
    searchBtn.addEventListener('click', () => {
      if (isMobile()) {
        postTrackEvent('nav_new_mobile_click_search', {});
      }
    });
  }
};

export default navTracking;
