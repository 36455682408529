import config from 'config';
import rootStore from '../../../../themes/store';

/**
 * Trigger store sync action with configurable
 * timeout to handle delay in any server updates
 */
const triggerSync = (cartToken, timeout = 2000) => setTimeout(() => {
  rootStore.dispatch('cart/syncServerChangeWithClient', cartToken);
}, timeout);


/**
 * Check and get expected basketchange localstorage key and data on change
 */
const getBasketChangeFromStorage = ({ key }) => {
  if (key === config.cart.basket_change_key) {
    const data = localStorage[key];
    const { cartToken } = JSON.parse(data);

    triggerSync(cartToken);
  }
}

/**
 * Flag basket change to trigger sync
 */
const triggerBasketChange = (fromCache = false) => {
  const cartToken = rootStore.getters['cart/getCartToken'];


  // If page was loaded from cache (eg: some browsers render a cached page when coming from back/forward button),
  // then the basket data will not be up to date we need to skip the
  // localstorage route and manually trigger the sync action, this is due to a browser
  // limitation that does not allow listing to storage change events from the same tab
  if (fromCache) {
    triggerSync(cartToken, 0);
  } else {
    // Set localstore for event listener to catch
    localStorage.setItem(
      config.cart.basket_change_key,
      JSON.stringify({
        timestamp: Date.now(),
        cartToken: cartToken
      })
    );
  }
};

const addEventListener = () => window.addEventListener('storage', getBasketChangeFromStorage);
const removeEventListener = () => window.removeEventListener('storage', getBasketChangeFromStorage);

export {
  triggerBasketChange,
  addEventListener,
  removeEventListener,
}
