<template lang="pug">
  .column.small-12.medium-6.countrypicker
    .picker-menu(v-show="menuOpen" v-bind:class="{'active': menuOpen}")
      .picker-menu-arrow
      ul
        li(v-for="item in choices" @click="selectLanguage(item)").picker-item
          span.text(v-html="item[0]")
          span.text(v-html="item[2]")
    .picker-wrapper(@click="toggleMenu")
      .picker-icon
        p.text(v-html="languageName")
      .picker-arrow(v-bind:class="arrowDirection")
</template>

<script>
export default {
  name: 'LanguagePicker',
  components: {},
  props: {
    'original': {
      type: String,
      default: ''
    },
    'choices': {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    arrowDirection() {
      if (this.menuOpen) {
        return 'up';
      }
      return 'down';
    },
    languageName() {
      let filted = this.choices.filter(e => e[0] === this.original)[0];
      if (filted !== undefined) {
        return filted[2];
      }
      return '';
    }
  },
  watch: {},
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    selectLanguage(item) {
      this.$emit('languageset', item);
      this.toggleMenu();
    }
  },
};
</script>
