import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import CartState from '../types/CartState';
import RootState from '../../../types/RootState';

export const module: Module<CartState, RootState> = {
  namespaced: true,
  state: {
    isMicrocartOpen: false,
    itemsAfterPlatformTotals: {},
    platformTotals: null,
    platformTotalSegments: null,
    cartIsLoaded: false,
    cartServerToken: '', // server side ID to synchronize with Backend (for example Magento)
    shipping: [],
    payment: [],
    minimumOrderThreshold: 0,
    cartItemsHash: '',
    cartServerLastSyncDate: 0,
    cartServerLastTotalsSyncDate: 0,
    cartItems: [], // note: check if it's properly namespaced
    cartSizeLoaded: false,
    paymentRequest: {},
    voucher: {},
  },
  getters,
  actions,
  mutations,
};
