import Vue from 'vue';
import {ActionTree} from 'vuex';
// requires cart module
import * as types from '../../../cart/store/mutation-types';
import StockState from '../../types/StockState';
import config from 'config';
import {processURLAddress} from '../../../../helpers';
import RootState from '../../../../types/RootState';
import {TaskQueue} from '../../../../lib/sync';
import {Logger} from '../../../../lib/logger';

const actions: ActionTree<StockState, RootState> = {
  /**
   * Reset current configuration and selected variatnts
   */
  async queueCheck(context, {product, qty = 1}) {
    if (config.stock.synchronize) {
      const task: any = await TaskQueue.queue({
        url: processURLAddress(`${config.stock.check_endpoint}?sku=${encodeURIComponent(product.sku)}`),
        payload: {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
          mode: 'cors'
        },
        is_result_cacheable: true, // store result for the Checkout.js double check
        product_sku: product.sku,
        callback_event: 'store:stock/stockAfterCheck'
      });
      return {
        qty: product.max_sale_qty || 0,
        status: product.max_sale_qty >= qty ? 'ok' : 'out_of_stock',
        onlineCheckTaskId: task.task_id
      }; // if online we can return ok because it will be verified anyway
    } else {
      return {
        qty: product.stock ? product.stock.qty : 0,
        status: product.stock ? (product.stock.is_in_stock ? 'ok' : 'out_of_stock') : 'volatile'
      }; // if not online, cannot check the source of true here
    }
  },
  /**
   * Reset current configuration and selected variatnts
   */
  async check(context, {product, qty = 1}) {
    if (config.stock.synchronize) {
      const task: any = TaskQueue.execute({
        url: processURLAddress(`${config.stock.check_endpoint}?sku=${encodeURIComponent(product.sku)}`),
        payload: {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
          mode: 'cors'
        },
        product_sku: product.sku
      });
      return {
        qty: task.result ? task.result.qty : 0,
        status: task.result ? (task.result.is_in_stock ? 'ok' : 'out_of_stock') : 'ok',
        onlineCheckTaskId: task.task_id
      }; // if online we can return ok because it will be verified anyway
    } else {
      return {
        qty: product.stock ? product.stock.qty : 0,
        status: product.stock ? (product.stock.is_in_stock ? 'ok' : 'out_of_stock') : 'volatile'
      }; // if not online, cannot check the source of true here
    }
  },
  /**
   * Reset current configuration and selected variants
   */
  list(context, {skus}) {
    if (config.stock.synchronize) {
      try {
        const task: any = TaskQueue.execute({
          url: processURLAddress(`${config.stock.list_endpoint}?skus=${encodeURIComponent(skus.join(','))}`),
          payload: {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            mode: 'cors'
          },
          skus: skus
        });
        if (task.resultCode === 200) {
          for (const si of task.result) {
            context.state.cache[si.product_id] = {
              is_in_stock: si.is_in_stock,
              qty: si.qty,
              product_id: si.product_id
            }; // note: should be moved to mutation
          }
        }
        return task; // if online we can return ok because it will be verified anyway
      } catch (err) {
        Logger.error(err, 'stock')();
        return null;
      }
    } else {
      return null; // if not online, cannot check the source of true here
    }
  },
  clearCache(context) {
    context.state.cache = {};
  },
  async stockAfterCheck(context, event) {
    setTimeout(async () => {
      // note: Move to cart module
      const cartItem: any = await context.dispatch('cart/getItem', event.product_sku, {root: true});
      if (cartItem && event.result.code !== 'ENOTFOUND') {
        if (!event.result.is_in_stock) {
          if (!config.stock.allowOutOfStockInCart && !config.cart.synchronize) { // if config.cart.synchronize is true then - the items are being removed by the result of cart/update action executed from cart/sync
            Logger.log('Removing product from cart' + event.product_sku, 'stock')();
            context.commit('cart/' + types.CART_DEL_ITEM, {product: {sku: event.product_sku}}, {root: true});
          } else {
            context.dispatch('cart/updateItem', {
              product: {
                errors: {stock: (window as any).PATCH_I18N.cart.out_of_stock},
                sku: event.product_sku,
                is_in_stock: false
              }
            }, {root: true});
          }
        } else {
          context.dispatch('cart/updateItem', {
            product: {
              info: {stock: (window as any).PATCH_I18N.cart.in_stock},
              sku: event.product_sku,
              is_in_stock: true
            }
          }, {root: true});
        }
        Vue.prototype.$bus.$emit('cart-after-itemchanged', {item: cartItem});
      }
      Logger.debug('Stock quantity checked for ' + event.result.product_id + ', response time: ' + (event.transmited_at - event.created_at) + ' ms', 'stock')();
      Logger.debug(event, 'stock')();
    }, 500);
  }
};

export default actions;
