<template lang="pug">
  div
    transition(name="search-container__animation")
      div(v-show="show").search-container__wrapper
        .row.hide-for-large
          .columns.small-12.search-container__close(@click="closeAll")
            svg
              use(xlink:href="#icon-close")
        .row
          .columns.medium-8.medium-offset-2.search-container__item
            .row
              .small-12.columns
                .form-group
                  .search-container__input
                    input.js-search-input(
                      type="search"
                      v-model="searchTerm"
                      v-on:keyup.enter="search"
                      :placeholder="placeholder"
                      )
                    div(:class="{'search-icon__wrapper--active': active}" @click="search").search-icon__wrapper
                      svg.search-icon__item
                        use(xlink:href="#icon-magnify-glass")
            .row(v-show="noresults")
              .small-12.columns
                .search-container__noresults
                  h2 {{ noResultTitle }}
                  h5 {{ noResultText }}
            .row
              .small-12.columns
                .row
                    .small-12.columns(v-if="showRecent" :class="recentClass")
                      suggestion-block(:title="'Recent'" :terms="resentReverse" :size="recentCount" v-on:searchChange="suggestionClick")
                    .small-12.columns(v-if="showPopular" :class="popularClass")
                      suggestion-block(:title="'Popular'" :terms="popular" :size="popularCount" v-on:searchChange="suggestionClick")
    transition(name="search-backdrop__animation")
      div(v-show="show" @click="closeAll").search-backdrop
</template>

<script>
import SuggestionBlock from './SuggestionBlock.vue';
import { postTrackEvent } from '../../utils/analytics';

export default {
  name: 'Search',
  components: {
    'suggestion-block': SuggestionBlock
  },
  props: {
    'show': {
      type: Boolean,
      default: false
    },
    'noresults': {
      type: Boolean,
      default: false
    },
    'placeholder': {
      type: String,
      default: ''
    },
    'popular': {
      type: Array,
      default() {
        return [
          {text: 'Indoor plants', count: 68, link: 'indoor/plants/'},
          {text: 'Outdoor plants', count: 52, link: 'outdoor/plants/'},
          {text: 'Indoor pots', count: 26, link: 'indoor/pots/'},
          {text: 'Outdoor pots', count: 22, link: 'outdoor/pots/'},
        ];
      }
    }
  },
  data() {
    return {
      localStorge: 'searchStorage',
      searchTerm: '',
      recent: [],
      active: false,
      noResultTitle: PATCH_I18N.search.noResultTitle,
      noResultText: PATCH_I18N.search.noResultText,
      url: 's/',
    };
  },
  beforeMount () {},
  mounted() {
    this.retrieveStorage();
    let pastSearch = window.searchDataResultCount;
    if (pastSearch) {
      if (pastSearch.text !== undefined || pastSearch.text !== '') {
        this.addToRecent(pastSearch.text, pastSearch.count);
      }
    }
  },
  computed: {
    resentReverse() {
      let element = this.recent;
      return element.reverse();
    },
    autoFocus () {
      if (this.show) {
        return 'autofocus';
      }
      return '';
    },
    popularCount() {
      if (this.recent.length > 0 && this.popular.length > 0) {
        return 3;
      } else if (this.recent.length === 0 && this.popular.length > 0) {
        return 6;
      } else {
        return 0;
      }
    },
    recentCount() {
      if (this.recent.length > 0 && this.popular.length > 0) {
        return 3;
      } else if (this.recent.length > 0 && this.popular.length === 0) {
        return 6;
      } else {
        return 0;
      }
    },
    recentClass() {
      if (this.recent.length > 0 && this.popular.length > 0) {
        return 'medium-6';
      } else if (this.recent.length > 0 && this.popular.length === 0) {
        return 'medium-12';
      } else {
        return '';
      }
    },
    popularClass() {
      if (this.recent.length > 0 && this.popular.length > 0) {
        return 'medium-6';
      } else if (this.recent.length === 0 && this.popular.length > 0) {
        return 'medium-12';
      } else {
        return '';
      }
    },
    showRecent() {
      let length = this.recent.length > 0;
      if (length) {
        return this.recent[0].text !== undefined;
      }
      return false;
    },
    showPopular() {
      let length = this.popular.length > 0;
      if (length) {
        return this.popular[0].text !== undefined;
      }
      return false;
    }
  },
  watch: {
    'searchTerm': {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.active = val.length > 0;
        }
      }
    }
  },
  methods: {
    suggestionClick (value) {
      // This will either go to the new search page or go to the plp page based
      // on if there is a link property on the input.
      if (value.link === undefined) {
        this.searchTerm = value.text;
        this.commitSearch();
      } else {
        let url = this.urlFinder();
        window.location = url + value.link;
      }
    },
    closeAll() {
      // This will save the elements then close it all.
      this.presitStorage();
      this.$emit('closesearch');
    },
    search() {
      this.commitSearch();
    },
    postEvent() {
      postTrackEvent('Search Start', {
        'term': this.searchTerm
      });
    },
    urlFinder() {
      // This will push out the url for the local page
      let regex = /\/\w{2}\/\w{2}\//g;
      let found = window.location.pathname.match(regex);
      let url = '/gb/en/';
      if (found != null && found.length > 0) {
        url = found[0];
      }
      return url;
    },
    buildQuery() {
      // This will take the this.url and this.searchTerm and create the final url
      // which will be used to call the PLP page
      let url = this.urlFinder();
      return url + this.url + '?q=' + encodeURIComponent(this.searchTerm);
    },
    addToRecent(term, count) {
      // This will go through the list of recent elements and update them
      let activeValue = null;
      this.recent.forEach(function(x, i) {
        if (x.text && term) {
          if (x.text.toLowerCase() === term.toLowerCase()) {
            activeValue = x;
          }
        }
      });
      if (activeValue !== null) {
        this.recent.pop({'text': activeValue.text, 'count': activeValue.count});
      }
      this.recent.push({'text': term, 'count': count});
    },
    commitSearch() {
      // This will call buildQuery and use the results from that to get the results.
      // After the call it will presit data which will save the
      this.presitStorage();
      this.postEvent();
      window.location = this.buildQuery();
    },
    presitStorage() {
      // This will take some of the this.data and save it to the LocalStorage
      // Will be called after each commit
      let recent = this.recent.filter((x) => x.text !== undefined);
      const parsed = JSON.stringify({
        'recent': recent,
        'searchTerm': this.searchTerm
      });
      localStorage.setItem(this.localStorge, parsed);
    },
    retrieveStorage() {
      // This will get the elements out of the localStorage and store the data from it
      if (localStorage.getItem(this.localStorge)) {
        try {
          let data = JSON.parse(localStorage.getItem(this.localStorge));
          let recent = data['recent'].filter((x) => x.text !== undefined);
          if (recent.length > 0) {
            this.recent = recent;
          } else {
            this.recent = [];
          }
          this.searchTerm = data['searchTerm'];
        } catch (e) {
          localStorage.removeItem(this.localStorge);
        }
      }
    }
  }
};
</script>
