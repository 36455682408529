import {ActionTree} from 'vuex';
import ProductState from '../../types/ProductState';
import RootState from '../../../../types/RootState';
import {TaskQueue} from '../../../../lib/sync';
import config from 'config';
import rootStore from '../../../../../themes/store';
import {getRegion} from '../../../../../utils/region';

/**
 * This file has been changed so that instead of using local storage and elasticsearch
 * we are calling the server to get any and all product information.
 */

async function _serverProductInfo({skus, token}) {
  /**
   * This will communicate with the server and retrieve the product information
   */
  const region = getRegion();
  const task = await TaskQueue.execute({
    url: config.products.product_info_endpoint,
    payload: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': token
      },
      mode: 'cors',
      body: JSON.stringify({
        sku: skus,
        region: region
      })
    },
    silent: true
  });
  return task;
}

const actions: ActionTree<ProductState, RootState> = {
  /**
   * List the products based on querying the backend for the requried stance
   * * @param {Object} query is the object of searchQuery class
   * @param {Int} start start index
   * @param {Int} size page size
   * @return {Promise} Of a product
   */
  async list(context, {query, start = 0, size = 50, entityType = 'product', sort = '', cacheByKey = 'sku', prefetchGroupProducts = true, updateState = false, meta = {}, excludeFields = null, includeFields = null, configuration = null, append = false, populateRequestCacheTags = true}) {
    const token = rootStore.getters['user/getUserToken'];
    return _serverProductInfo({
      skus: query,
      token: token,
    }).then((resp) => {
      if (resp.result && resp.result.length) {
        return resp.result;
      }
    });
  },
  /**
   * Search products by specific field
   * @param {Object} options
   */
  async single(context, {options, setCurrentProduct = true, selectDefaultVariant = true, assignDefaultVariant = false, key = 'sku', skipCache = false}) {
    if (!options[key]) {
      throw Error('Please provide the search key ' + key + ' for product/single action!');
    }
    return context.dispatch('list', { // product list syncs the platform price on it's own
      query: [options[key]],
      prefetchGroupProducts: false,
      updateState: false
    }).then((res) => {
      const prd = res.filter(x => x.sku === options[key])[0];
      return prd;
    });
  },
};

export default actions;
