import { render, staticRenderFns } from "./LoadingSpinner.vue?vue&type=template&id=880c064e&scoped=true&functional=true&"
var script = {}
import style0 from "./LoadingSpinner.vue?vue&type=style&index=0&id=880c064e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "880c064e",
  null
  
)

export default component.exports