<template lang="pug">
  navigation-desktop(v-if="!isMobile" key="nav-1")
  navigation-mobile(v-else key="nav-2")
</template>

<script>

import NavigationDesktop from './NavigationDesktop.vue';
import NavigationMobile from './NavigationMobile.vue';
import { isMobile } from '../../utils/deviceHelpers';


export default {
  name: 'Navigation',
  data() {
    return {
      isMobile: false
    }

  },
  components: {
    'navigation-desktop': NavigationDesktop,
    'navigation-mobile': NavigationMobile,
  },
  beforeMount() {
    this.isMobile = isMobile();    
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = isMobile();
    }
  }
};
</script>
