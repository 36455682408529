import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observable, action, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import zenscroll from 'zenscroll';
import postMessage from '../../utils/postMessage';
import {postTrackEvent} from '../../utils/analytics';

import QuantitySelector from './QuantitySelector';

@observer
export class CartAddVariant extends Component {
  @observable variant = null;
  @observable variants = [];
  @observable sending = false;

  constructor(props) {
    super(props);

    runInAction(() => {
      const variants = props.product.variants.sort(item => item.price);
      this.state = {
        variants: variants.map(item => ({value: item.pk, ...item})),
        isInStock: true,
      };
      this._setDefaultVariant();
    });
  }

  _setDefaultVariant = () => {
    let defaultVariant = this.state.variants.find(item => item.is_in_stock);
    this.state.variants.forEach(item => {
      if (
        item['is_default_variant'] &&
        item['is_in_stock']
      ) {
        defaultVariant = item;
      }
    });
    if (!defaultVariant) {
      defaultVariant = this.state.variants[0];
    }
    this._onVariantChanged(defaultVariant);
  };

  componentDidMount = () => {
    // no need to get stock data from API
    this.setState({isInStock: this.props.isInStock});
  };

  _onVariantChanged = action(variant => {
    this.variant = variant;

    if (this.props.onVariantChanged) {
        this.props.onVariantChanged(this.variant);
    }
  });

  _productPayload = (variant, quantity) => {
    return {
      products: [
        {
          variant: variant.pk,
          sku: variant.sku,
          quantity: Math.min(quantity, 99),
          method: 'add',
          name: variant.product_name || variant.name,
        },
      ],
    };
  };

  _onAddToCart = action((quantity, location = null) => {
    this.sending = true;
    postMessage('message', {data: {functionName: 'dismissAllMessages'}});
    // Capture in scope in case it changes during xhr.
    const variant = this.variant;
    const variantPayload = this._productPayload(variant, quantity);

    postMessage('cart', {
      ...variantPayload,
      location: location || this.props.location,
    });

    this.sending = false;

    const recommended = document.querySelectorAll(
      `[data-recommendation-title='${variant.pk}']`
    );

    // finds any pot card for the current container requirement
    const pots = document.querySelector(
      '.js-item-containers:not(.is-hidden) .gallery__item'
    );

    if (pots) {
      postTrackEvent('Pot scroll after adding to bag', {});
      zenscroll.center(pots, 1000, 225);
    }

    // temporary override so product can UAT that - because this will be unavailable
    // if there's a test for the above
    if (recommended.length && !pots) {
      zenscroll.center(recommended[0], 1000, 225);
    }
  });

  render() {
    return (
      <div>
        <QuantitySelector
          disabled={this.sending}
          onAddToCart={this._onAddToCart}
          isLarge={this.props.isLarge}
          index={this.props.index}
          default_quantity={this.props.default_quantity}
        />
      </div>
    );
  }
}

CartAddVariant.propTypes = {
  default_quantity: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isLarge: PropTypes.bool.isRequired,
  onVariantChanged: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  isInStock: PropTypes.bool,
  location: PropTypes.string,
};
