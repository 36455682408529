import { Store } from 'vuex';
import rootStore from '../core/store';
import { module as cartModule } from '../core/modules/cart/store';
import { Ui as uiModule } from '../core/ui-store';
import { Recommendations as recommendationsModule } from '../core/recommendations-store';
import { module as userModule } from '../core/modules/user/store';
import { stockModule } from '../core/modules/catalog/store/stock';
import { productModule } from '../core/modules/catalog/store/product';
import { paymentModule } from '../core/modules/checkout/store/payment';
import { shippingModule } from '../core/modules/checkout/store/shipping';

// Building the Store together based on modules
const store = new Store(rootStore);
store.registerModule('cart', cartModule);
store.registerModule('ui', uiModule);
store.registerModule('recommendations', recommendationsModule);
store.registerModule('user', userModule);
store.registerModule('stock', stockModule);
store.registerModule('product', productModule);
store.registerModule('payment', paymentModule);
store.registerModule('shipping', shippingModule);
store.state.version = process.env.APPVERSION;

export default store;
