import Vue from 'vue';
import CountryPicker from './components/i18n/countryPicker.vue';
import LanguagePicker from './components/i18n/languagePicker.vue';
import RegionPicker from './components/i18n/RegionPicker.vue';
import FrenchBanner from './components/i18n/FrenchBanner.vue';
import axios from 'axios';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

(function () {
  if (document.getElementById('localisePicker')) {
    new Vue({
      el: '#localisePicker',
      delimiters: ['<%', '%>'],
      components: {
        'country-picker': CountryPicker,
        'language-picker': LanguagePicker,
        'region-picker': RegionPicker,
        'french-banner': FrenchBanner,
      },
      data() {
        return {
          localise: {},
          country: '',
          language: '',
          region: '',
          url: '',
          selectlang: '',
          selectcountry: '',
          senddata: {},
          errorMsg: '',
          text: PATCH_I18N.picker.location
        };
      },
      methods: {
        languageselect(value) {
          let bodyFormData = new FormData();
          bodyFormData.set('language', value[0]);
          bodyFormData.set('country', this.country.toLowerCase());
          this.senddata = bodyFormData;
          this.sessionRequest();
        },
        countryselect(value) {
          let bodyFormData = new FormData();
          bodyFormData.set('country', value[0]);
          this.senddata = bodyFormData;
          this.sessionRequest();
        },
        regionselect(value) {
          let bodyFormData = new FormData();
          bodyFormData.set('country', value[2]);
          bodyFormData.set('language', value[3]);
          this.senddata = bodyFormData;
          this.sessionRequest();
        },
        sessionRequest() {
          // This makes the first call to the endpoint to change the session
          let data = this.senddata;
          this.errorMsg = '';
          data.set('path', window.location.pathname);
          axios({
            method: 'post',
            url: '/set_i18n_location/',
            data: data,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken',
          }).then((response) => {
            let data = response['data'];
            window.location = data['url'];
          }).catch((error) => {
            this.errorMsg = 'There has been a problem with selecting ' + error.response.data;
          });
        }
      },
      mounted() {
        if (document.querySelector('#localisePicker')) {
          let body = document.querySelector('#localisePicker');
          this.localise = JSON.parse(body.attributes['data-localise'].value);
          this.country = body.attributes['data-country'].value;
          this.language = body.attributes['data-language'].value;
          this.region = body.attributes['data-region'].value;
          this.url = body.attributes['data-url'].value;
        }
      }
    });
  }
})();
