<template>
  <div
    class="cart-product"
    :id="product.id"
  >
    <div :class="productContainerClass">
      <div class="row">
        <div class="small-4 column cart-product__thumbnail">
          <div class="cart-product__thumbnail--img-container">
            <a
              :href="product.url_key"
            ><img
              class="cart-product__thumbnail--img"
              :src="product.image"
            ></a>
          </div>
        </div>
        <div class="small-5 column cart-product__product-info">
          <p class="cart-product__primary-line">
            <a :href="product.url_key">{{ getSlicedName(product.name) }}</a>
          </p>
          <p class="cart-product__secondary-line">
            {{ getSlicedName(product.variant_name) }}
          </p>
          <button
            class="cart-product__remove-button"
            @click="triggerRemoveItem"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 28 28"
            >
              <use xlink:href="#rubbish_bin" />
            </svg>
          </button>
        </div>
        <div class="small-3 column cart-product__price-data">
          <p class="cart-product__primary-line cart-product__total">
            {{ priceValue }}
          </p>
          <base-input-number
            :value="productQty"
            :min="1"
            :max="product.max_sale_qty"
            :out-of-stock-error="outOfStockError"
            :data-price="product.price"
            :data-sku="product.sku"
            :data-id="product.id"
            @input="prepareUpdateQuantity"
            @loading="sendLoading"
          />
        </div>
      </div>
      <div
        class="cart-product__error-message"
        v-if="errorVisible"
      >
        <div :class="errorBoxClass">
          <div>
            <svg
              class="cart-product__warning-icon"
              width="16"
              height="16"
              viewBox="0 0 32 32"
            >
              <use xlink:href="#alert_circle" />
            </svg>
          </div>
          <p>{{ errorMessage }}</p>
        </div>
      </div>
    </div>
    <div>
      <div
        class="row"
        v-if="!product.is_in_stock && product.url_key"
      >
        <div
          class="small-12 column cart-product__error-message-box cart-product__error-message-box-oos"
        >
          <svg
            class="cart-product__warning-icon"
            width="16"
            height="16"
            viewBox="0 0 32 32"
          >
            <use xlink:href="#alert_circle" />
          </svg>
          <p>{{ getOutOfStockText }}</p>
          <button
            class="cart-product__remove-button-alert"
            @click="triggerRemoveItem"
          >
            {{ getRemoveText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import BaseInputNumber from '../Form/BaseInputNumber';
import { debounce } from './../../../../../utils/eventListenerPageHelpers';
import { getCurrencyStr } from './../../../../../utils/currencyFormatting.js';
import { postTrackEvent } from './../../../../../utils/analytics';
import Product from '../../../../../core/compatibility/components/blocks/Microcart/Product';

export default {
  components: {
    BaseInputNumber,
  },
  mixins: [Product],
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
  computed: {
    getOutOfStockText () {
      return PATCH_I18N.cart.messages.out_of_stock_cart;
    },
    getRemoveText () {
      return PATCH_I18N.cart.remove_line;
    },
    productContainerClass () {
      const baseClass = 'cart-product__item-row';
      const oosClass = 'cart-product__error-out-of-stock';
      return this.product.is_in_stock ? baseClass : `${baseClass} ${oosClass}`;
    },
    errorMessage () {
      return this.outOfStock ? PATCH_I18N.cart.messages.out_of_stock_long : PATCH_I18N.cart.messages.quantity_error;
    },
    priceValue () {
      if (this.product.price !== undefined && this.product.price !== null) {
        return getCurrencyStr(this.product.price);
      }
      return null;
    },
    productQty () {
      return this.product.qty;
    },
    errorBoxClass () {
      const base = 'small-12 column cart-product__error-message-box';
      return this.outOfStock ? `${base} cart-product__error-arrow-max-qty-exceeded` : `${base} cart-product__error-arrow-remove`;
    }
  },
  data () {
    return {
      outOfStock: false,
      errorVisible: false,
      quantityError: false,
      outOfStockError: false,
    };
  },
  beforeMount() {
    this.prepareUpdateQuantity = debounce(this.prepareUpdateQuantity, 750);
  },
  methods: {
    sendLoading(state, force) {
      this.$emit('loading', state, force);
    },
    triggerRemoveItem() {
      postTrackEvent('Item removed', { product_id: this.product.id });
      this.sendLoading(true);
      const el = $(`#${this.product.id}`);
      el.addClass('animated');
      el.addClass('fadeOut');

      const cartItems = this.$store.getters['cart/getCartItems'];
      const isLastItem = cartItems.length === 1;
      const animatedClasses = ['animated', 'fadeOut', 'slow'];
      if (isLastItem) {
        animatedClasses.push('delay-1s');
        const totalContainer = document.querySelector('.cart__total-container');
        const cartHeadliner = document.querySelector('.cart__headline');
        const continueButton = document.querySelector('.cart__button-continue');
        const closeBtn = document.querySelector('.cart__close-btn');
        totalContainer?.classList.add(...animatedClasses);
        cartHeadliner?.classList.add(...animatedClasses);
        if (continueButton) {
          continueButton.classList.add(...animatedClasses);
        }
        closeBtn?.classList.add(...animatedClasses);
      } else {
        const isOnlyUnshippable = cartItems.filter(product => product.id !== this.product.id).every(product => !product.is_shipping_required);
        if (isOnlyUnshippable) {
          const deliveryPrice = document.querySelector('.js-cart-delivery-price');
          deliveryPrice?.classList.add(...animatedClasses);
        }
      }

      el.on('animationend', () => {
        el.hide('slow', () => {
          this.removeItem();
        });
      });
    },
    getSlicedName (name) {
      if (name) {
        const slicedName = name.slice(0, 18);
        return slicedName.length === 18 ? `${slicedName}...` : slicedName;
      }
    },
    prepareUpdateQuantity(quantity) {
      if (quantity < 1) {
        this.errorVisible = true;
        this.quantityError = true;
        quantity = 1;
        this.sendLoading(false, true);
        postTrackEvent(
          'Cart error',
          { error: 'Error tried to remove item by minus icon', product: this.product },
        );
      } else if (this.product.is_in_stock) {
        const element = this;
        this.$store.dispatch('stock/queueCheck', { product: this.product, qty: quantity }, { root: true }).then((result) => {
          if (result.status === 'ok') {
            element.updateQuantity(quantity);
            this.errorVisible = false;
            this.outOfStock = false;
            this.outOfStockError = false;
          } else {
            this.product.qty = this.product.max_sale_qty;
            element.updateQuantity(this.product.max_sale_qty);
            this.errorVisible = true;
            this.outOfStock = true;
            this.outOfStockError = true;
            postTrackEvent(
              'Cart error',
              { error: 'Max item amount error shown', product: this.product },
            );
          }
          setTimeout(() => {
            this.sendLoading(false);
          }, 1250);
        });
      } else {
        this.sendLoading(false, true);
      }
    }
  },
  mounted() {
    if (!this.product.is_in_stock && this.product.url_key) {
      postTrackEvent(
        'Cart error',
        { error: 'Product out of stock error shown', product: this.product }
      );
    } else if (this.product.qty > this.product.max_sale_qty) {
      // show error as they requested more than available
      // handles scenario where added to cart from PLP for product already in cart
      this.prepareUpdateQuantity(this.product.qty);
    } else if (this.product.qty < this.product.qty_requested) {
      // quantity is right, but show the error as they requested more
      this.prepareUpdateQuantity(this.product.qty_requested);
      // error only needs to be displayed once so can now be removed
      this.product.qty_requested = null;
    }
    document.addEventListener('click', (e) => {
      if (!e.target.classList.contains('cart-product__error-message-box')) {
        this.errorVisible = false;
      }
    });
  }
};
</script>
