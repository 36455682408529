function getOptions (inter) {
  /*
    This gets the meta information from the event and add it
    to the
   */
  return {
    context: {
      traits: {
        gclid: window.analyticsDataLayer.gclid,
      },
      userAgent: navigator.userAgent
    },
    integrations: inter
  };
}

function updateProperties (rawData) {
  /*
    This will update the properties and including the
    localisation elements.
   */
  var data = rawData;
  Object.assign(data, window.analyticsDataLayer.testTags);
  return data;
}

export function postTrackEvent(eventName, valueData, integrations, postingSaved) {
  /*
   * This will post the analytics event to analytics and will take the
   * value data then combine it to the page data.
   */
  if (!valueData) {
    valueData = {};
  }

  if (!integrations) {
    integrations = {
      All: true
    };
  }

  if (window.rudderanalytics) {
    const properties = updateProperties(valueData);
    const options = getOptions(integrations);
    if (postingSaved !== true) postSavedEvents();
    window.rudderanalytics.track(eventName, properties, options);
  } else {
    saveEvent(eventName, valueData, integrations);
  }

  // remove the event listener before adding it again to avoid duplicate events
  window.removeEventListener('message', postSavedEvents);
  window.addEventListener('message', postSavedEvents);
};

export function saveEvent(eventName, valueData, integrations) {
  const storedEvents = localStorage.getItem('storedEvents');
  const event = {
    eventName: eventName,
    valueData: valueData,
    integrations: integrations
  };
  if (storedEvents) {
    const parsedStoredEvents = JSON.parse(storedEvents);
    parsedStoredEvents.push(event);
    localStorage.setItem('storedEvents', JSON.stringify(parsedStoredEvents));
  } else {
    localStorage.setItem('storedEvents', JSON.stringify([event]));
  }
}

export function postSavedEvents (message) {
  if (message === undefined || message.data === 'analytics_loaded') {
    const storedEvents = localStorage.getItem('storedEvents');
    if (storedEvents) {
      localStorage.removeItem('storedEvents');
      const parsedStoredEvents = JSON.parse(storedEvents);
      const failedEvents = [];
      parsedStoredEvents.forEach(event => {
        try {
          postTrackEvent(event.eventName, event.valueData, event.integrations, true);
        } catch {
          failedEvents.push(event);
        }
      });
      if (failedEvents.length) {
        localStorage.setItem('storedEvents', JSON.stringify(failedEvents));
      }
    }
  }
}

export function postIdentifyEvent(userId, traits) {
  /*
   * This will call the identify element of the Analytics
   */
  if (window.rudderanalytics) {
    window.rudderanalytics.identify(userId, traits);
  };
}

export function postAliasEvent(to, from, options, callback) {
  /*
   * This will call the alias function of the Analytics
   */
  if (window.rudderanalytics) {
    window.rudderanalytics.alias(to, from, options, callback);
  }
}

document.onvisibilitychange = () => {
  if (document.visibilityState === 'hidden' && window.rudderanalytics) {
    postSavedEvents();
  }
};

window.onbeforeunload = () => {
  if (window.rudderanalytics) {
    postSavedEvents();
  }
};
