const moment = require('moment');

moment.updateLocale('fr', {
  months: PATCH_I18N.moment.months.split('_'),
  monthsShort: PATCH_I18N.moment.monthsShort.split('_'),
  monthsParseExact: true,
  weekdays: PATCH_I18N.moment.weekdays.split('_'),
  weekdaysShort: PATCH_I18N.moment.weekdaysShort.split('_'),
  weekdaysMin: PATCH_I18N.moment.weekdaysMin.split('_'),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm'
  },
  calendar: {
    lastDay: PATCH_I18N.moment.lastDay,
    sameDay: PATCH_I18N.moment.sameDay,
    nextDay: PATCH_I18N.moment.nextDay,
    lastWeek: PATCH_I18N.moment.lastWeek,
    nextWeek: PATCH_I18N.moment.nextWeek,
    sameElse: PATCH_I18N.moment.sameElse,
  },
  relativeTime: {
    future: 'dans %s',
    past: 'il y a %s',
    s: 'quelques secondes',
    m: 'une minute',
    mm: '%d minutes',
    h: 'une heure',
    hh: '%d heures',
    d: 'un jour',
    dd: '%d jours',
    M: 'un mois',
    MM: '%d mois',
    y: 'un an',
    yy: '%d ans'
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? 'er' : 'e');
  },
  meridiemParse: /PD|MD/,
  isPM: function (input) {
    return input.charAt(0) === 'M';
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? 'PD' : 'MD';
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4 // Used to determine first week of the year.
  }
});

moment.updateLocale('en', {
  months: PATCH_I18N.moment.months.split('_'),
  monthsShort: PATCH_I18N.moment.monthsShort.split('_'),
  weekdays: PATCH_I18N.moment.weekdays.split('_'),
  weekdaysShort: PATCH_I18N.moment.weekdaysShort.split('_'),
  weekdaysMin: PATCH_I18N.moment.weekdaysMin.split('_'),
  calendar: {
    lastDay: PATCH_I18N.moment.lastDay,
    sameDay: PATCH_I18N.moment.sameDay,
    nextDay: PATCH_I18N.moment.nextDay,
    lastWeek: PATCH_I18N.moment.lastWeek,
    thisWeek: PATCH_I18N.moment.thisWeek,
    nextWeek: PATCH_I18N.moment.nextWeek,
    sameElse: PATCH_I18N.moment.sameElse,
  }
});

moment.calendarFormat = function (myMoment, now) {
  var diff = myMoment.diff(now, 'days', true);
  var nextMonth = now.clone().add(1, 'month');

  var retVal = diff < -6 ? 'sameElse'
    : diff < -1 ? 'lastWeek'
      : diff < 0 ? 'lastDay'
        : diff < 1 ? 'sameDay'
          : diff < 2 ? 'nextDay'
            : diff < 7 ? 'thisWeek'
              : diff < 14 ? 'nextWeek'
              // introduce thisMonth and nextMonth
                : (myMoment.month() === now.month() && myMoment.year() === now.year()) ? 'thisMonth'
                  : (nextMonth.month() === myMoment.month() && nextMonth.year() === myMoment.year()) ? 'nextMonth' : 'sameElse';
  return retVal;
};
