import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

@observer
export default class Messages extends Component {
  _clear = (key, event) => {
    event.preventDefault();
    this.props.store.dismissMessage(key);
  };

  render() {
    if (this.props.store.messages.size === 0) {
      return null;
    }

    const messages = this.props.store.messages.entries().map(([key, {label, message, die}]) => (
      <li className={`message__item message__item--${label} ${die ? 'message__item--dismiss' : 'message__item--remain'} `} key={key}>
        <div className="row">
          <div className="small-12 column">
            <div className="message__inner">
              <ul>{message.map((msg, i) => <li key={i}><span>{msg}</span></li>)}</ul>
              <button className="message__clear" onClick={this._clear.bind(this, key)}>
                <svg width="20px" height="20px" viewBox="22 222 124 124">
                  <polygon stroke="none" fill="#FFFFFF" fillRule="nonzero" points="145.4062 327.9375 128.3906 345.2344 84.0938 300.9375 39.7969 345.2344 22.7812 328.2188 67.0781 284.2031 23.0625 239.9062 40.0781 222.6094 84.375 266.9062 128.3906 222.8906 145.125 239.9062 101.3906 283.9219"></polygon>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </li>
    ));
    return (
      <div className="message">
        <ul className="message__list">{messages}</ul>
      </div>
    );
  }
}

Messages.propTypes = {
  store: PropTypes.object.isRequired
};
