<template lang="pug">
  div(v-bind:class="{ show: isActive }").frenchBanner
    .circle-clipper
      .countryflag.flag-fr
    .frenchBanner--textbox
      p.bold Il semblerait que vous êtes en France
      p Souhaitez-vous être redirigé vers le site français?

    div(@click="gotoRegion").button Visiter le site français
    div(@click="closebanner").button.hollow Rester sur le site Anglais

    .frenchBanner__cross(@click="closebanner")

</template>

<script>
import { postTrackEvent } from '../../utils/analytics';

export default {
  name: 'FrenchBanner',
  components: {},
  props: {
    'region': {
      type: String,
      default: 'gb-london',
    }
  },
  data() {
    return {
      'frregion': ['fr-paris', 'Paris', 'fr', 'fr', 'français'],
      'show': false,
      'cookieName': 'french-banner'
    };
  },
  computed: {
    isActive() {
      return this.show;
    }
  },
  watch: {
    'region': {
      immediate: true,
      handler(val, oldVal) {
        this.checkLanguage();
      }
    }
  },
  methods: {
    setRegion() {
      this.$emit('regionset', this.frregion);
    },
    closebanner() {
      this.show = false;
      postTrackEvent('French banner', {'show': false, 'redirect': false});
      this.setCookie();
    },
    gotoRegion() {
      postTrackEvent('French banner', {'show': false, 'redirect': true});
      this.setCookie();
      this.setRegion();
    },
    getCookie() {
      let name = this.cookieName + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');

      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
    setCookie() {
      let d = new Date();
      d.setTime(d.getTime() + (1000 * 24 * 60 * 60 * 1000));
      let expires = 'expires=' + d.toUTCString();
      document.cookie = this.cookieName + '=SET;' + expires + ';path=/';
    },
    checkLanguage() {
      // This will check the navigation language and cookie
      let navLang = navigator.languages &&
        navigator.languages[0] ||
        navigator.language ||
        navigator.userLanguage;
      let selectLang = '';
      if (navLang.indexOf('-') > -1) {
        selectLang = navLang.split('-')[0];
      } else {
        selectLang = navLang.substring(0, 2);
      }
      let cookie = this.getCookie();

      if (this.region && this.region !== 'fr-paris' && selectLang !== 'en') {
        if (cookie === '') {
          this.show = true;
          postTrackEvent('French banner', {'show': true});
        }
      }
    }
  }
};
</script>
