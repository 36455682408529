import {
  observable,
  action,
} from 'mobx';

class MessageStore {
  TIMEOUT = 5000;

  ACTIONS = {
    'dismissAllMessages': this.dismissAllMessages,
    'dismissMessage': this.dismissMessage,
    'addWarning': this.addWarning,
    'addSuccess': this.addSuccess,
  }

  @observable messages = observable.map({});
  counter = 0;

  constructor(messages) {
    messages.map(action((m) => {
      // Success messages should expire
      const die = (m.label === 'info' || m.label === 'success');
      this.addMessage(m.label, m.message, die, false);
    }));

    window.addEventListener('message', (event) => {
      if (event.data && event.data.type !== 'message') { return; }
      const data = event.data.data;
      if (typeof (data) !== 'undefined') {
        if (typeof (data.data['param']) !== 'undefined') {
          this.ACTIONS[data.data['functionName']](data.data['param']);
        } else {
          this.ACTIONS[data.data['functionName']]();
        }
      }
    }, false);
  }

  @action addInfo = (messageText, die = false, replace = true) => {
    return this.addMessage('info', messageText, die, replace);
  }

  @action addSuccess = (messageText, die = true, replace = true) => {
    return this.addMessage('success', messageText, die, replace);
  }

  @action addWarning = (messageText, die = false, replace = true) => {
    return this.addMessage('warning', messageText, die, replace);
  }

  @action addError = (messageText, die = false, replace = true) => {
    return this.addMessage('error', messageText, die, replace);
  }

  @action addMessage = (label, messageText, die = false, replace = true) => {
    if (replace) {
      this.dismissAllMessages();
    }

    const key = ++this.counter;
    this.messages.set(key, {
      key,
      label,
      message: messageText,
      die: die
    });

    if (die) {
      setTimeout(action(() => {
        this.dismissMessage(key);
      }), this.TIMEOUT);
    }

    return key;
  }

  @action dismissAllMessages = () => {
    this.messages.clear();
  }

  @action dismissMessage = (key) => {
    if (this.messages.has(key)) {
      this.messages.delete(key);
    }
  }
}

export default new MessageStore(window.messagesBootstrap);
