import $ from 'jquery';
import axios from 'axios';
import { postIdentifyEvent } from './utils/analytics';

$(() => {
  function clearError() {
    $('#newsletterform__error').fadeOut();
    $('#newsletterform > #emailaddress').removeClass('error');
  }

  function newsletterSend() {
    const csrftoken = $($('input[name="csrfmiddlewaretoken"]')[0]).val();

    const email = $('#newsletterform > #emailaddress').val();

    let countryCode = 'GB';
    let languageCode = 'en';
    let region = 'gb-london';
    if (document.getElementById('localisePicker')) {
      countryCode = $('#localisePicker').attr('data-country');
      languageCode = $('#localisePicker').attr('data-language');
      region = $('#localisePicker').attr('data-region');
    }
    const baseUrl = '/' + countryCode.toLowerCase() + '/' + languageCode;

    // Identify in Analytics
    postIdentifyEvent(email, { email });

    const data = {
      email: email,
      location: 'site-footer',
      ometria_segments: [258],
      prismic_signup: false,
      region: region,
    };

    axios
      .post(`${baseUrl}/newsletter/`, data, {
        headers: {
          'X-CSRFToken': csrftoken,
        }
      })
      .then((res) => {
        clearError();
        if (res.status === 'error') {
          $('#newsletterform__error').fadeIn();
          $('#newsletterform > #emailaddress').addClass('error');
        } else {
          $('#newsletterform > #emailaddress').val('');
          $('#newsletterform').fadeOut();
          $('#newsletterform__complete').fadeIn();
        }
      })
      .catch(() => {
        $('#newsletterform__error').fadeIn();
        $('#newsletterform > #emailaddress').addClass('error');
      });
  }

  $(document).on('click', '#newsletterform > button', function() {
    newsletterSend();
  }).on('keydown', '#newsletterform > #emailaddress', function(event) {
    if (event.keyCode === 13) {
      newsletterSend();
    }
  });
});
