import {getPrefix} from './prefix.js';

export function getCurrencyStr (price, convertDecimals = false, show = false) {
  /* This is converting the number into a currenct string with a symbol */
  const language = getPrefix().substr(4, 2);
  const currency = window.currentCurrency[0];
  const fraction = convertDecimals && price % 1 === 0 ? 0 : 2;

  const options = {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction
  };
  if (price || show) {
    return price.toLocaleString(language, options);
  }
}

export function formatPrice (priceValue, currency) {
  const currencies = {
    'GBP': '£',
    'EUR': '€'
  };
  const currencySymbol = currencies[currency];
  return `${currencySymbol || ''}${(Math.round(priceValue * 100) / 100).toFixed(2)}`;
}
