import { getPrefix, getCountryCode } from './prefix';

// Note: SpeedCurve external script is added to the <head> in base.html

const speedCurveLabels = () => {
  // set label prefix, gb does not need a label prefix
  const countryCode = getCountryCode();
  const labelPrefix = countryCode && countryCode !== 'gb' ? `${countryCode}-` : '';
  const pathname = window.location.pathname;

  // get page type
  let luxLabel = 'other';
  if (/^\/([A-Za-z]{2})\/([A-Za-z]{2})\/$/i.test(pathname)) {
    luxLabel = 'home';
  } else if (/^\/([A-Za-z]{2})\/([A-Za-z]{2})\/(w)\//i.test(pathname)) {
    luxLabel = 'plp';
  } else if (/^\/([A-Za-z]{2})\/([A-Za-z]{2})\/(\w+)\/[a-z]+(?:-[a-z]+)*-\d+\/$/i.test(pathname)) {
    luxLabel = 'pdp';
  } else if (/^\/([A-Za-z]{2})\/([A-Za-z]{2})\/(checkout)\//i.test(pathname)) {
    luxLabel = 'checkout';
  } else if (/^\/([A-Za-z]{2})\/([A-Za-z]{2})\/(order)\//i.test(pathname)) {
    luxLabel = 'order-confirmation';
  } else if (/^\/([A-Za-z]{2})\/([A-Za-z]{2})\/(read)\//i.test(pathname)) {
    luxLabel = 'magazine';
  } else {
    const excludeList = [
      'faqs',
      'about',
      'cookies',
      'privacy-policy',
      'contact',
      'careers',
      'terms-of-service',
      'returns-policy'
    ];
    const urlBase = getPrefix();
    const pagePath = urlBase.length > 0 ? pathname.replace(urlBase, '') : pathname;
    if (excludeList.includes(pagePath.replace(/\/$/, ''))) {
      luxLabel = 'static';
    }
  }
  luxLabel = labelPrefix + luxLabel;
  const LUX = window.LUX || {};
  LUX.label = luxLabel;
};

export default speedCurveLabels;
