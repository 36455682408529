<template>
  <div>
    <div class="row">
      <div class="small-12 column cart__close-button-container">
        <button
          @click="closeMicrocartExtend"
          :data-testid="closeMicrocart"
        >
          <svg
            class="cart__close-button"
            viewBox="0 0 28 28"
          >
            <use xlink:href="#close_button" />
          </svg>
        </button>
      </div>
    </div>
    <div class="row empty-cart__container">
      <div class="small-12 column cart__empty-container">
        <h4 class="h--body-font">
          {{ cartHeadlineText }}
        </h4>
        <svg
          class="cart__empty-container--icon"
          width="180"
          height="180"
          viewBox="0 0 80 80"
        >
          <use href="#empty_cart" />
        </svg>
        <p class="text-center">
          <a
            class="button button--with-icon cart__button-continue"
            @click="closeMicrocartExtend"
          >
            <span class="button__icon">
              <svg
                class="icon--padlock"
                width="15"
                height="17"
                viewBox="0 0 28 28"
              >
                <use xlink:href="#icon-arrow-left" />
              </svg>
            </span>
            <span class="cart__checkout-button-text">{{ continueShoppingText }}</span>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { postTrackEvent } from './../../../../../utils/analytics';
import onEscapePress from '../../../../../core/mixins/onEscapePress';
import Microcart
  from '../../../../../core/compatibility/components/blocks/Microcart/Microcart';

export default {
  mixins: [
    onEscapePress,
    Microcart,
  ],
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    cartHeadlineText() {
      return this.isLoading ? PATCH_I18N.cart.loading_cart : PATCH_I18N.cart.empty_new;
    },
    continueShoppingText() {
      return PATCH_I18N.cart.continue_shopping;
    },
  },
  methods: {
    closeMicrocartExtend () {
      this.closeMicrocart();
      this.$store.commit('ui/setSidebar', false);
      if (this.isLoading) {
        postTrackEvent('Closed cart with Loading basket Continue Shopping', { value: true });
      } else {
        this.addVoucherPressed = false;
        postTrackEvent('Closed cart with Empty basket Continue Shopping', { value: true });
      }
    },
    onEscapePress () {
      this.closeMicrocart();
    },
  },
  mounted() {
    if (!this.isLoading) {
      // make sure that the recommendations stored in localStorage are erased
      localStorage.removeItem('storedRecommendation');
    }
  }
};
</script>
