export default function postMessage(type, data) {
  const origin = window.origin;
  let domain = '';
  if (origin !== undefined) {
    domain = origin + '/messages/';
  } else {
    domain = window.location.href;
  }
  const payload = {
    type: type,
    data: data
  };
  window.postMessage(payload, domain);
}
