export const throttle = (fn, delay) => {
  // throttles given function to only run every `delay` ms
  let previouslyRunTime, runTimer;

  return function callFn(...args) {
    const now = Date.now();

    runTimer = clearTimeout(runTimer);

    if (!previouslyRunTime || (now - previouslyRunTime >= delay)) {
      fn.apply(null, args);
      previouslyRunTime = now;
    } else {
      runTimer = setTimeout(callFn.bind(null, ...args), delay - (now - previouslyRunTime));
    }
  };
};

export const debounce = (func, wait, immediate) => {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

const eventListenerPageHelpers = () => {
  const headerContainer = document.querySelector('.js-header-container');
  const headerElement = document.querySelector('.js-header');
  let headerHeight = headerElement?.getBoundingClientRect().height || 0;
  let cookieBannerHeight = 0;
  let cookieBannerActive = false;

  let windowScrolled = 0;

  window.addEventListener('scroll', throttle(scroll => {
    const ws = window.scrollY;

    if (windowScrolled < ws && ws > 0) {
      document.body.classList.add('scroll-down');
    } else {
      document.body.classList.remove('scroll-down');
    }
    windowScrolled = ws;

    const cookiebannerElement = document.querySelector('.cookiebanner');
    const cookieBannerActiveNow = cookiebannerElement?.classList.contains('active');
    if (cookieBannerActiveNow !== cookieBannerActive) {
      // get the height of the cookie banner
      cookieBannerActive = cookieBannerActiveNow;
      cookieBannerHeight = cookieBannerActiveNow ? cookiebannerElement.getBoundingClientRect().height : 0;
    }
    const headerContainerHeight = headerHeight + cookieBannerHeight;
    if (headerContainerHeight <= ws) {
      if (!(headerContainer.classList.contains('header--sticky'))) {
        headerContainer.classList.add('header--sticky');
        setTimeout(function() {
          // prevent flash on inital scroll
          headerContainer.classList.add('header--transition');
        }, 100);
      }
    } else if (ws < 1) {
      // only need to unstick if user scrolls back to top
      headerContainer.classList.remove('header--sticky');
      headerContainer.classList.remove('header--transition');
    }
  }, 100), { passive: true });

  window.addEventListener('resize', function() {
    const cookiebannerElement = document.querySelector('.cookiebanner');
    headerHeight = headerElement?.getBoundingClientRect().height || 0;
    cookieBannerHeight = cookiebannerElement?.classList.contains('active') ? cookiebannerElement.getBoundingClientRect().height : 0;
  });
};

export default eventListenerPageHelpers;
