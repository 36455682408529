import $ from 'jquery';
import { postTrackEvent } from './utils/analytics';

class CookieController {
  constructor($el) {
    this.$el = $el;
    this.cookiebannername = 'cookie-control-banner';
    this.$banner = $el.querySelector('.cookiebanner');
    this.$header = $el.querySelector('.header-container');
    this.$accept = $el.querySelector('.cookiebanner--accept');
    this.$nav = $el.querySelector('.nav--mobile');
  }
  configureCookie() {
    let cookiebanner = this._readCookie(this.cookiebannername);
    if (cookiebanner === null) {
      this._showBanner();
    }
  }
  _createCookie(name, value, days) {
    if (!days) {
      days = 1000;
    }
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    let expires = '; expires=' + date.toGMTString();
    document.cookie = name + '=' + value + expires + '; path=/';
  }
  _readCookie(name) {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  _pushAllDown() {
    let banner = $(this.$banner);
    let headerContainer = $(this.$header);
    let nav = $(this.$nav);
    let header = $('header');
    let desktopSearch = $('#searchContainer');
    headerContainer.css('transition', 'margin 1s ease-in-out').css('margin-top', banner.outerHeight() + 'px');
    desktopSearch.addClass('search-container-low');
    header.css('transition', 'top 1s ease-in-out').css('top', banner.outerHeight() + 'px');
    nav.css('transition', 'margin 1s ease-in-out').css('margin-top', banner.outerHeight() + 'px');
  }
  _removePushDown() {
    let headerContainer = $(this.$header);
    let header = $('header');
    let nav = $(this.$nav);
    let desktopSearch = $('#searchContainer');
    headerContainer.css('margin-top', 'inherit');
    desktopSearch.removeClass('search-container-low');
    header.css('top', '0px');
    nav.css('margin-top', 'inherit');
  }
  _showBanner() {
    let then = this;
    let banner = $(then.$banner);
    banner.css('display', 'block');
    setTimeout(function () {
      banner.addClass('active');
      if (banner.hasClass('cookieblock')) {
        then._pushAllDown();
      }
    }, 100);
    postTrackEvent('Cookie Banner Shown', {
      'width': window.innerWidth,
      'height': window.innerHeight,
    });
  }
  _hideBanner() {
    let then = this;
    let banner = $(then.$banner);
    banner.removeClass('active');
    if (banner.hasClass('cookieblock')) {
      then._removePushDown();
    }
    setTimeout(function () {
      banner.css('display', 'none');
    }, 2000);
    this._createCookie(then.cookiebannername, 1);
    postTrackEvent('Cookie Banner Hidden', {
      'width': window.innerWidth,
      'height': window.innerHeight,
    });
  }
  _acceptCookiesClick() {
    this._hideBanner();
    this._createCookie(this.cookiebannername, 'seen');
  }
  attachEvents() {
    $(this.$accept).on('click', this._acceptCookiesClick.bind(this));
  }
}

$(() => {
  const cookieel = new CookieController(document);
  cookieel.configureCookie();
  cookieel.attachEvents();
});
