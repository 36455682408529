<template lang="pug">
  .header__bag-container
    div(@click="bagClicked()" :class="{'header__bag--is-active': cartReady }").header__bag.link-negative
      transition(name="a-item-num-changed")
        p(ref="numberCircle" :class="{'header__num-items--is-active': cartReady }").header__num-items {{ cartItemTotal }}
      transition(name="a-item-num-changed")
        svg.header__icon
          use(v-bind="{'xlink:href':cartIcon}")
</template>

<script>
import store from './store';
import { mapGetters } from 'vuex';

export default {
  name: 'CartIcon',
  store,
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      cartItemTotal: 'cart/getItemsTotalQuantity',
      cartItems: 'cart/getCartItems',
      cartSizeLoaded: 'cart/getCartSizeLoaded',
    }),
    cartIcon () {
      if (this.cartReady) {
        return '#icon-shopping-bag-black';
      }
      return '#icon-shopping-bag';
    },
    cartReady () {
      return this.cartSizeLoaded && this.cartItemTotal > 0;
    }
  },
  watch: {
    cartItemTotal (ov, v) {
      const element = this.$refs.numberCircle;
      if (!element) {
        return;
      }
      element.classList.add('a-item-num-changed-animate');
      setTimeout(function() {
        element.classList.remove('a-item-num-changed-animate');
      }, 1500);
    }
  },
  methods: {
    bagClicked () {
      const locationHref = window.location.href;
      if (!locationHref.endsWith('?cart=open')) {
        history.pushState('', 'Cart open', '?cart=open');
      }
      this.$store.dispatch('ui/toggleMicrocart');
    }
  }
};
</script>
