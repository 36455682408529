// Core dependecies
import { Microcart } from '../../../../modules/cart/components/Microcart';

export default {
  methods: {
    closeMicrocart() {
      // only need to toggle if open
      if (this.$store.state.ui.microcart) {
        this.toggleMicrocart();
        // removes the '?cart=open' from the URL
        history.replaceState('', 'Cart closed', window.location.pathname);
      }
    },
  },
  computed: {
    isMicrocartOpen() {
      return this.$store.state.ui.microcart;
    }
  },
  mixins: [
    Microcart
  ]
};
