import {GetterTree} from 'vuex';
import {sumBy, filter} from 'lodash-es';
import CartState from '../types/CartState';
import RootState from '../../../types/RootState';
import AppliedVoucher from '../types/AppliedVoucher';
import {calcItemsHmac, onlineHelper} from '../../../helpers';
import config from 'config';
import {Logger} from '../../../lib/logger';

const getters: GetterTree<CartState, RootState> = {
  getCartToken(state) {
    return state.cartServerToken;
  },
  getLastSyncDate(state) {
    return state.cartServerLastSyncDate;
  },
  getLastTotalsSyncDate(state) {
    return state.cartServerLastTotalsSyncDate;
  },
  getShippingMethod(state) {
    return state.shipping;
  },
  getPaymentMethod(state) {
    return state.payment;
  },
  getLastCartHash(state) {
    return state.cartItemsHash;
  },
  getCurrentCartHash(state) {
    return calcItemsHmac(state.cartItems, state.cartServerToken, state.voucher);
  },
  isCartHashChanged(state, getters) {
    return getters.getCurrentCartHash !== state.cartItemsHash;
  },
  isSyncRequired(state, getters) {
    return !state.cartItemsHash || (getters.getCurrentCartHash !== state.cartItemsHash) || !state.cartServerLastSyncDate; // first load - never synced
  },
  isTotalsSyncRequired(state, getters) {
    return !state.cartItemsHash || (getters.getCurrentCartHash !== state.cartItemsHash) || !state.cartServerLastTotalsSyncDate; // first load - never synced
  },
  isCartHashEmtpyOrChanged(state, getters) {
    return !state.cartItemsHash || (getters.getCurrentCartHash !== state.cartItemsHash);
  },
  getCartItems(state) {
    return state.cartItems;
  },
  getCartIsLoaded(state) {
    return state.cartIsLoaded;
  },
  getCartSizeLoaded(state) {
    return state.cartSizeLoaded;
  },
  getMinimumOrderThreshold(state) {
    return state.minimumOrderThreshold;
  },
  isTotalsSyncEnabled(state) {
    return config.cart.synchronize_totals && onlineHelper.isOnline;
  },
  isCartConnected(state) {
    return !!state.cartServerToken;
  },
  isCartSyncEnabled(state) {
    return config.cart.synchronize && onlineHelper.isOnline;
  },
  getTotals(state) {
    if (state.platformTotalSegments && onlineHelper.isOnline) {
      return state.platformTotalSegments;
    } else {
      const shipping = state.shipping instanceof Array ? state.shipping[0] : state.shipping;
      const payment = state.payment instanceof Array ? state.payment[0] : state.payment;
      const totalsArray = [
        {
          code: 'subtotalInclTax',
          title: (window as any).PATCH_I18N.cart.subtotal_inc_tax,
          value: sumBy(filter(state.cartItems, p => p.is_in_stock), (p) => {
            return p.qty * p.price;
          })
        },
        {
          code: 'grand_total',
          title: (window as any).PATCH_I18N.cart.grand_total,
          value: sumBy(filter(state.cartItems, p => p.is_in_stock), (p) => {
            return p.qty * p.price + (shipping ? shipping.price_incl_tax : 0);
          })
        }
      ];
      if (payment) {
        totalsArray.push({
          code: 'payment',
          title: payment.title,
          value: payment.costInclTax
        });
      }
      if (shipping) {
        totalsArray.push({
          code: 'shipping',
          title: shipping.method_title,
          value: shipping.price_incl_tax
        });
      }
      return totalsArray;
    }
  },
  getItemsTotalQuantity(state) {
    if (config.cart.minicartCountType === 'items') {
      return state.cartItems.length;
    }

    return sumBy(state.cartItems, (p) => {
      return p.qty;
    });
  },
  getVoucher(state): AppliedVoucher | {} {
    return state.voucher;
  },
  /** @deprecated */
  totalQuantity(state) {
    Logger.error('The getter cart.totalQuantity has been deprecated please change to cart.getters.getItemsTotalQuantity()')();
  },
  /** @deprecated */
  totals(state) {
    Logger.error('The getter cart.totals has been deprecated please change to cart.getters.getTotals()')();
  },
  isVirtualCart(state) {
    return state.cartItems.every((itm) => {
      return itm.type_id === 'downloadable' || itm.type_id === 'virtual'; // check for downloadable & virtual products
    });
  },
  getPaymentRequest(state) {
    return state.paymentRequest;
  },
};

export default getters;
