const BACKLINK_CLASS_NAME = '.js-back-link';

export default function backLink() {
  const onBackLink = (event) => {
    event.preventDefault();

    window.history.back();
  };
  const links = document.querySelectorAll(BACKLINK_CLASS_NAME);

  for (const link of links) {
    link.addEventListener('click', onBackLink);
    link.addEventListener('touchend', onBackLink);
  }
}
