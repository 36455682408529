export function getPrefix () {
  /* returns the base of the URL, which is needed to make an API call */
  let regex = /\/\w{2}\/\w{2}\//g;
  let found = window.location.pathname.match(regex);
  let url = '/gb/en/';
  if (found?.length > 0) {
    url = found[0];
  }
  return url;
}

export function getCountryCode () {
  /*
   *  Uses regex to get the country code out of the url
   */
  const regex = /\/\w{2}\/\w{2}\//g;
  const regexCountry = /\w{2}/;
  const found = window.location.pathname.match(regex);
  let country = 'gb';
  if (found?.length > 0) {
    const prefix = found[0].match(regexCountry);
    if (prefix !== undefined && prefix.length > 0) {
      country = prefix[0];
    }
  }
  return country;
}
