<template lang="pug">
  .region-picker
    .region-picker_cover
      span.countryflag.small(v-bind:class="flag(selectedRegion)")
      span.text(v-html="displayText(selectedRegion)")
      .chevron
    .region-picker_input
      select(v-model="chosenRegion" @change="setRegion()")
        option(v-for="item in choices" :value="item[0]")
          span.text(v-html="displayText(item)")

</template>

<script>
export default {
  name: 'RegionPicker',
  components: {},
  props: {
    'region': {
      type: String,
      default: 'gb-london',
    },
    'choices': {
      type: Array,
      default: () => {
        return [];
      }
    },
    'location': {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      chosenRegion: '',
    };
  },
  computed: {
    selectedRegion() {
      return this.choices.filter(c => c[0] === this.region)[0];
    }
  },
  watch: {
    region: {
      immediate: true,
      handler(val, oldVal) {
        this.chosenRegion = val;
      }
    }
  },
  methods: {
    setRegion() {
      let item = this.choices.filter(c => c[0] === this.chosenRegion)[0];
      this.$emit('regionset', item);
    },
    flag(value) {
      if (value && value.length > 2) {
        return 'flag-' + value[2].toLowerCase();
      }
    },
    displayText(value) {
      if (value && value.length > 4) {
        // needs to display UK for the London region as until the checkout it can be
        // London or Nationwide
        const region = value[1] === 'London' ? 'UK' : value[1];
        return `${region} - ${value[4]}`;
      } else {
        return '';
      }
    }
  }
};
</script>
