import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observable, action, runInAction} from 'mobx';
import {observer} from 'mobx-react';

@observer
export default class QuantitySelector extends Component {
  @observable quantity;

  constructor(props) {
    super(props);
    runInAction(() => {
      this.quantity = this.props.default_quantity || 1;
    });
    this.idForLabel = `add_to_cart_${this.props.index}`;
  }

  _setQuantity = action(value => {
    this.quantity = Number(value);
  });

  _onQuantityChange = action(event => {
    this._setQuantity(event.currentTarget.value);
  });

  _onAddItem = action(event => {
    event.preventDefault();
    this.props.onAddToCart(this.quantity);
    event.target.blur();
    this._setQuantity(1);
  });

  render() {
    return (
      <div className="quantity-selector">
        <label htmlFor={this.idForLabel} className="show-for-sr">
          {PATCH_I18N.cart.choose_quantity}
        </label>
        <input
          className={`quantity-selector__input ${
            this.props.isLarge ? '' : 'quantity-selector__input--short'
          }`}
          id={this.idForLabel}
          type="number"
          pattern="[0-9]*"
          min={1}
          onChange={this._onQuantityChange}
          value={this.quantity}
          disabled={this.props.disabled}
        />
        <button
          className={`quantity-selector__button button ${
            this.props.isLarge ? '' : 'card__button button--product'
          }`}
          onClick={this._onAddItem}
          disabled={this.props.disabled}
        >
          {PATCH_I18N.cart.add}{' '}
          <span className="card__hide-text">
            {PATCH_I18N.cart.to_bag}
          </span>
        </button>
      </div>
    );
  }
}

QuantitySelector.propTypes = {
  default_quantity: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  isLarge: PropTypes.bool.isRequired,
  onAddToCart: PropTypes.func.isRequired,
};
