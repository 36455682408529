import {Module} from 'vuex';
import actions from './actions';
import StockState from '../../types/StockState';
import RootState from '../../../../types/RootState';

export const stockModule: Module<StockState, RootState> = {
  namespaced: true,
  actions,
  state: {
    cache: {}
  }
};
