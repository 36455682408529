import {Module} from 'vuex';
import axios from 'axios';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const store = {
  namespaced: true,
  state: {
    recommendation: {},
  },
  mutations: {
    setRecommendation(state, recommendation) {
      state.recommendation = recommendation;
    },
  },
  actions: {
    pullRecommendations({commit, state}) {
      return axios({
        method: 'get',
        url: '/api/cart/pull-recommendations/',
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
      }).then((response) => {
        commit('setRecommendation', response['data']);
      }).catch((error) => {
        console.log('error', error);
      });
    },
  },
  getters: {
    getRecommendation(state) {
      return state.recommendation;
    },
  }
};

export const Recommendations: Module<any, any> = store;
