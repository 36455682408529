export const standardDeliveryCost = 3;
export const freeDeliveryThreshold = 50;

export function getDeliveryPrice(subtotal, isShippingRequired) {
  // TODO: Get delivery price and threshold from backend
  return (subtotal < freeDeliveryThreshold) && isShippingRequired ? standardDeliveryCost : 0;
}

export function slotHasCapacity(slot) {
  const slotCapacity = slot.capacity;
  const locationHasCapacity = slot.locationBookingCount < slotCapacity;
  // maxBookings is a percentage of capacity (or null if no max)
  const slotMaxBookings = slot.maxBookings;
  const maxBookingsNotReached =
    slotMaxBookings === null ||
    slot.bookingCount < Math.round(slotCapacity * slotMaxBookings / 100);
  return locationHasCapacity && maxBookingsNotReached;
}
