import Vue from 'vue';
// eslint-disable-next-line camelcase
import { sha3_224 } from 'js-sha3';
import config from 'config';

/**
 * Return configurable product thumbnail depending on the configurable_children
 * @param {object} product
 * @param {bool} ignoreConfig
 */
export function productThumbnailPath(product, ignoreConfig = false) {
  let thumbnail = product.image;
  if ((product.type_id && product.type_id === 'configurable') && product.hasOwnProperty('configurable_children') &&
    product.configurable_children.length && (ignoreConfig || !product.is_configured) &&
    ('image' in product.configurable_children[0])
  ) {
    thumbnail = product.configurable_children[0].image;
    if (!thumbnail || thumbnail === 'no_selection') {
      const childWithImg = product.configurable_children.find(f => f.image && f.image !== 'no_selection');
      if (childWithImg) {
        thumbnail = childWithImg.image;
      } else {
        thumbnail = product.image;
      }
    }
  }
  return thumbnail;
}

export function once(key, fn) {
  const { process = {} } = global;
  const processKey = key + '__ONCE__';
  if (!process.hasOwnProperty(processKey)) {
    // Logger.debug(`Once ${key}`, 'helper')()
    process[processKey] = true;
    fn();
  }
}

export const processURLAddress = (url: string = '') => {
  if (url.startsWith('/')) return `${config.api.url}${url}`;
  return url;
};

/*
  * serial executes Promises sequentially.
  * @param {funcs} An array of funcs that return promises.
  * @example
  * const urls = ['/url1', '/url2', '/url3']
  * serial(urls.map(url => () => $.ajax(url)))
  *     .then(Logger.log.bind(Logger))()
  */
export const serial = async promises => {
  const results = [];
  for (const item of promises) {
    const result = await item;
    results.push(result);
  }
  return results;
};

// Online/Offline helper
export const onlineHelper = Vue.observable({
  isOnline: navigator.onLine
});

// helper to calcuate the hash of the shopping cart
export const calcItemsHmac = (items:any, token:any, voucher:any) => {
  return sha3_224(JSON.stringify({ items, token, voucher }));
};
