import config from 'config';
import { MicrocartProduct } from '../../../../modules/cart/components/Product';

export default {
  data() {
    // deprecated
    return {};
  },
  beforeMount() {
    // deprecated, will be moved to theme or removed in the near future #1742
    this.$bus.$on('cart-after-itemchanged', this.onProductChanged);
    this.$bus.$on('notification-after-itemremoved', this.onProductRemoved);
  },
  beforeDestroy() {
    // deprecated, will be moved to theme or removed in the near future #1742
    this.$bus.$off('cart-after-itemchanged', this.onProductChanged);
    this.$bus.$off('notification-after-itemremoved', this.onProductRemoved);
  },
  methods: {
    removeItem() {
      if (config.cart.askBeforeRemoveProduct) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          item: this.product,
          message: window.PATCH_I18N.cart.remove_item,
          action2: {label: window.PATCH_I18N.general.ok, action: this.removeFromCart},
          action1: {label: window.PATCH_I18N.general.cancel, action: 'close'},
          hasNoTimeout: true
        });
      } else {
        this.removeFromCart();
      }
    },
    updateQuantity(newQuantity) {
      let quantity = parseInt(newQuantity);
      if (quantity < 1) quantity = 1;
      MicrocartProduct.methods.updateQuantity.call(this, quantity);
    },
    onProductChanged(event) {
      // deprecated, will be moved to theme or removed in the near future #1742
      if (event.item.sku === this.product.sku) {
        this.$forceUpdate();
      }
    },
    onProductRemoved(event) {
      if (event.item.sku === this.product.sku) {
        this.removeFromCart(event.item);
      }
    }
  },
  mixins: [
    MicrocartProduct
  ]
};
