export function isMobile() {
  // check if the 'js-mobile-check' element is displayed, this element gets hidden on mobile viewports
  const isMobileElement = document.querySelector('.js-mobile-check');
  const isMobileElementDisplayed = isMobileElement ? window.getComputedStyle(isMobileElement).display : null;
  const isMobile = isMobileElementDisplayed === 'none';
  return isMobile;
}

export function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  const html = document.documentElement;
  return (
    rect.height > 0 &&
    rect.width > 0 &&
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || html.clientHeight) &&
    rect.right <= (window.innerWidth || html.clientWidth)
  );
};
