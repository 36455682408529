import {Module} from 'vuex';

const store = {
  namespaced: true,
  state: {
    sidebar: false,
    microcart: false,
    wishlist: false,
    searchpanel: false,
    newsletterPopup: false,
    overlay: false,
    loader: false,
    authElem: 'login',
    checkoutMode: false,
    openMyAccount: false,
    submenu: {
      depth: false,
      path: []
    },
    zendeskWidget: {
      show: true,
      offset: {
        horizontal: 0,
        vertical: 0,
        mobile: {
          horizontal: 0,
          vertical: 0,
        }
      },
      defaultOffset: {
        horizontal: 0,
        vertical: 0,
        mobile: {
          horizontal: 0,
          vertical: 0,
        }
      },
    },
  },
  mutations: {
    setCheckoutMode(state, action) {
      state.checkoutMode = action === true;
    },
    setMicrocart(state, action) {
      state.microcart = action === true;
      state.overlay = action === true;
    },
    setSidebar(state, action) {
      state.sidebar = action === true;
      state.overlay = action === true;
    },
    setSubmenu(state, {id, depth}) {
      if (id) {
        state.submenu.path.push(id);
      } else if (state.submenu.path.length) {
        setTimeout(() => {
          state.submenu.path.pop();
        }, 300);
      }
      state.submenu.depth = state.submenu.depth > 0 && depth;
    },
    setSearchpanel(state, action) {
      state.searchpanel = action === true;
      state.overlay = action === true;
    },
    setWishlist(state, action) {
      state.wishlist = action === true;
      state.overlay = action === true;
    },
    setOverlay(state, action) {
      state.overlay = action === true;
    },
    setLoader(state, action) {
      state.loader = action === true;
    },
    setAuthElem(state, action) {
      state.authElem = action;
    },
    setZendeskWidgetVisibility(state, action) {
      state.zendeskWidget.show = action;
    },
    setZendeskOffset(state, offset) {
      state.zendeskWidget.offset = offset;
    },
    setZendeskDefaultOffset(state) {
      state.zendeskWidget.offset = state.zendeskWidget.defaultOffset;
    },
  },
  actions: {
    toggleMicrocart({commit, state}) {
      commit('setMicrocart', !state.microcart);
    },
    toggleWishlist({commit, state}) {
      commit('setWishlist', !state.wishlist);
    },
    showZendeskWidget({commit, state}) {
      commit('setZendeskWidgetVisibility', true)
    },
    hideZendeskWidget({commit, state}) {
      commit('setZendeskWidgetVisibility', false)
    },
  },
  getters: {
    getZendeskWidgetVisibility(state) {
      return state.zendeskWidget.show;
    },    
    getZendeskWidgetOffset(state) {
      return state.zendeskWidget.offset;
    },
  }
};

export const Ui: Module<any, any> = store;
