import React, { Component } from 'react';
import {observable, action} from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import postMessage from '../../utils/postMessage';

@observer
export class CartAddCollection extends Component {
  @observable sending = false;
  @observable disabled = false;

  _productsPayload = (products) => {
    return {
      'products': products.map((product) => {
        const variant = product.variant || product.variants[0];
        return {
          'variant': variant.pk,
          'sku': variant.sku,
          'quantity': product.quantity ? Math.min(product.quantity, 99) : 1,
          'method': 'add',
          'name': product.display_name || variant.name,
        };
      }),
      'collection': this.props.collectionName,
    };
  };

  _onAdd = action((event) => {
    event.preventDefault();
    event.target.blur();

    this.sending = true;
    postMessage('message', {'data': {'functionName': 'dismissAllMessages'}});

    const productsPayload = this._productsPayload(this.props.products);

    postMessage('cart', productsPayload);

    this.disabled = true;
  });

  render() {
    const label = this.disabled ? PATCH_I18N.cart.collection_added : this.props.buttonLabel;
    return <a href="" className="button expanded button--product" onClick={this._onAdd} disabled={this.disabled}>{label}</a>;
  }
}

CartAddCollection.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  collectionName: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired
};
