import AppliedVoucher from '../types/AppliedVoucher';
import Product from '../../catalog/types/Product';
import CartTotalSegments from '../types/CartTotalSegments';

export const Microcart = {
  name: 'Microcart',
  computed: {
    productsInCart(): Product[] {
      return this.$store.state.cart.cartItems;
    },
    appliedVoucher(): AppliedVoucher | false {
      return this.$store.getters['cart/getVoucher'];
    },
    totals(): CartTotalSegments {
      return this.$store.getters['cart/getTotals'];
    },
    isOpen(): boolean {
      return this.$store.state.cart.isMicrocartOpen;
    }
  },
  methods: {
    applyVoucher(code: string): Promise<boolean> {
      return this.$store.dispatch('cart/applyVoucher', code);
    },
    removeVoucher(): Promise<boolean> {
      return this.$store.dispatch('cart/removeVoucher');
    },
    toggleMicrocart(): void {
      this.$store.dispatch('ui/toggleMicrocart');
    }
  }
};
