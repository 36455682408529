<template>
  <transition
    v-on:before-enter="setupAnimation"
    v-on:after-enter="changeZendeskOffset"
    v-on:before-leave="hideZendesk"
    v-on:leave="clearUpAnimation"
    v-on:after-leave="showRevertedZendesk"
    enter-active-class="animated slideInRight"
    enter-to-class="animated slideInRight"
    leave-active-class="animated slideOutRight"
    leave-to-class="animated slideOutRight"
    :name="direction === 'right' ? 'slide-left' : direction === 'left' ? 'slide-right' : null "
    :duration="{ enter: 1000, leave: 1500 }">
    <div
      data-testid="sidebar"
      v-if="isOpen"
      class="cart-container js-cart-container"
    >
      <component
        :is="component"
        @close="$emit('close')"
        @reload="getComponent"
      />
    </div>
  </transition>
</template>

<script>
import LoadingSpinner from './LoadingSpinner.vue';
import LoadingError from './LoadingError.vue';

export default {
  props: {
    asyncComponent: {
      type: Function,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    /** "right" or "left"  */
    direction: {
      type: String,
      default: 'right'
    }
  },
  data () {
    return {
      component: null
    };
  },
  created () {
    this.getComponent();
  },
  methods: {
    changeZendeskOffset (el) {
      const checkoutBtnContainer = document.querySelector('.cart__checkout-continue-container');
      if (checkoutBtnContainer) {
        const mobileVerticalPosition = window.innerHeight - checkoutBtnContainer.offsetTop;
        this.$store.commit('ui/setZendeskOffset', {
            horizontal: '500px',
            vertical: '0',
            mobile: {
              vertical: mobileVerticalPosition + 'px',
              horizontal: '0px',
            }
          })
        this.$store.dispatch('ui/showZendeskWidget');
      }
    },
    setupAnimation (el) {
      this.$store.dispatch('ui/hideZendeskWidget');
      document.documentElement.classList.add('no-scroll');
      const cartFadeOut = document.querySelector('.cart-fadeout');
      cartFadeOut.classList.add('show');
    },
    hideZendesk (el) {
      this.$store.dispatch('ui/hideZendeskWidget');
    },
    showRevertedZendesk (el) {
      this.$store.commit('ui/setZendeskDefaultOffset');
      this.$store.dispatch('ui/showZendeskWidget');
    },
    clearUpAnimation (el) {
      document.documentElement.classList.remove('no-scroll');
      const cartFadeOut = document.querySelector('.cart-fadeout');
      if (cartFadeOut) {
        cartFadeOut.classList.remove('show');
      }
    },
    getComponent () {
      this.component = () => ({
        component: this.asyncComponent(),
        loading: LoadingSpinner,
        error: LoadingError,
        timeout: 3000
      });
    }
  }
};
</script>
