<template>
  <div>
    <div class="row empty-cart__container">
      <div class="small-12 column cart__empty-container cart__instant-pay-complete">
        <h4 class="text-center title--payment-complete">
            Payment complete!
        </h4>
        <svg
          width="120"
          height="160"
          viewBox="0 0 32 34"
        >
          <use xlink:href="#trolley" />
        </svg>
        <div class="payment-complete-msg" v-if="isSuccessful">
          <h4 class="h--body-font title--loading">
            We're just finishing up
            <svg
              width="30"
              height="12"
            >
              <use xlink:href="#loading_animation" />
            </svg>          
          </h4>
          <p class="mt20">Please do not close this tab, you will be automatically redirected.</p>
        </div>
        <div class="payment-complete-msg" v-else>
          <h4 class="h--body-font">
            Sorry, something went wrong when we tried to finalise your order.         
          </h4>
          <p class="mt20"><strong>Please <a href="/gb/en/contact/">contact us</a> to confirm your order.</strong></p>
        </div>        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isSuccessful: {
      type: Boolean,
      required: false,
      default: true
    }
  },
};
</script>
