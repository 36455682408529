<template lang="pug">
  div.search-recommendation__block
    h6.search-recommendation__title.h--body-font {{ title }}
    ul(v-if="showTerms" :class="columnCount").search-recommendation__list
      li(v-for="(item, index) in terms"  @click="$emit('searchChange', item)" v-if="index < size")
        sline(:text="item.text" :count="item.count" v-if="item.text")
</template>

<script>
import SuggestionLine from './SuggestionLine.vue';

export default {
  name: 'SuggestionBlock',
  components: {
    'sline': SuggestionLine
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    terms: {
      type: Array,
      default() {
        return [];
      }
    },
    size: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
    };
  },
  computed: {
    columnCount() {
      return 'search-recommendation__list__c' + parseInt(this.size / 3);
    },
    showTerms() {
      let length = this.terms.length > 0;
      if (length) {
        return this.terms[0].text !== undefined;
      }
      return false;
    }
  },
  watch: {},
  methods: {},
};
</script>
