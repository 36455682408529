<template>
  <transition
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
    @after-leave="dispatchAddItem"
    mode="out-in"
  >
    <div
      class="row cart-recommendation"
      v-if="recommendedVariant && !hasAddedRecommendation && recommendedVariant.name"
    >
      <div class="small-4 column cart-recommendation__img-container">
        <a
          :href="recommendedVariant.url_key"
          @click="trackLinkClick"
        ><img :src="recommendedVariant.image"></a>
      </div>
      <div class="small-8 column pl-0">
        <p class="cart-recommendation__headline">
          <span v-if="showGiftWrapping">Add gift wrapping</span>
          <span v-else>Add a little treat:</span>
        </p>
        <p
          v-if="showGiftWrapping"
          class="cart-recommendation__description"
        >
          It's the finishing touch
        </p>
        <p
          v-else
          class="cart-recommendation__description"
        >
          {{ recommendedVariant.name }}
        </p>
        <div class="cart-recommendation__total-container">
          <span>{{ priceValue }}</span>
          <button
            class="button card__button button--product button--cart-add"
            @click="addRecommendation"
          >
            {{ addText }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCurrencyStr } from './../../../../../utils/currencyFormatting.js';
import { postTrackEvent } from './../../../../../utils/analytics';
import dayjs from 'dayjs';

export default {
  name: 'Recommendation',
  data () {
    return {
      recommendedVariant: null,
      hasAddedRecommendation: false,
    };
  },
  props: {
    productsInCart: {
      type: Array,
      required: true,
      default: () => [],
    },
    showSmallPlants: Boolean,
    showGiftWrapping: Boolean
  },
  computed: {
    ...mapGetters({
      recommendation: 'recommendations/getRecommendation',
      cartToken: 'cart/getCartToken',
    }),
    addText () {
      return PATCH_I18N.cart.add;
    },
    priceValue () {
      if (this.recommendedVariant.price !== undefined && this.recommendedVariant.price !== null) {
        return getCurrencyStr(this.recommendedVariant.price);
      }
      return null;
    },
  },
  watch: {
    recommendation: {
      immediate: false,
      deep: true,
      handler (value, oldValue) {
        /* This one is triggered only when pullRecommendationFromBackend is called
        and when the value contains the recommendation.
        When the data is pulled - it is saved in the localStorage, along with expiry
        date which is 1 day from now, and the cartID. */
        if (value.sku !== oldValue?.sku) {
          const recommendationObject = {
            recommendation: value,
            expiryDate: dayjs().add(1, 'day').format('YYYY MMM D, h:mm:ss'),
            cartId: this.cartToken,
            hasAddedRecommendation: false,
          };
          const recommendationObjectJSON = JSON.stringify(recommendationObject);
          localStorage.setItem('storedRecommendation', recommendationObjectJSON);
          postTrackEvent('Recommendation feature shown', {
            recommendation: value, sku: value.sku, isGiftWrapping: this.showGiftWrapping
          });
          this.recommendedVariant = value;
        }
      }
    },
  },
  mounted() {
    this.getRecommendation();
  },
  methods: {
    trackLinkClick () {
      postTrackEvent('Clicked to the recommendation PDP', { value: true });
    },
    getRecommendation() {
      /* LocalStorage will be checked first to look for the recommendation, rather
      than pulling in the data from the backend all the time, in order to avoid
      changing the recommendation too often */
      const storedRecommendation = localStorage.getItem('storedRecommendation');

      if (storedRecommendation) {
        const parsedRecommendation = JSON.parse(storedRecommendation);

        // check if recommendation is less than a day old
        const isRecommendationExpired = dayjs(parsedRecommendation.expiryDate).isBefore(dayjs());

        // check if user has this item in cart already, or if the item has been added
        /// previously. If yes, don't show recommendation
        const hasItemInCart = this.productsInCart.find((product) => {
          return product.id === parsedRecommendation.recommendation.id;
        });
        if ((hasItemInCart || parsedRecommendation.hasAddedRecommendation) && !isRecommendationExpired) {
          this.recommendedVariant = null;
          return;
        }
        // check if the recommendation belongs to the same cart session
        const isCartTokenMatching = this.cartToken === parsedRecommendation.cartId;

        if (!isRecommendationExpired && isCartTokenMatching) {
          this.checkStock(parsedRecommendation.recommendation);
          return;
        }
      }
      this.pullRecommendationFromBackend();
    },
    checkStock(recommendation) {
      /*
      Checks if the recommendation stored in localStorage is still in stock.
      If yes - set it as the recommendedVariant.
      If no - pull a new one from the backend.
      */
      this.$store.dispatch('product/list', {
        query: [recommendation.sku]
      }, { root: true }).then((resp) => {
        // make sure the product being recommended is in stock
        if (resp[0]?.is_in_stock) {
          this.recommendedVariant = recommendation;
          postTrackEvent('Recommendation feature shown', {
            recommendation: recommendation, sku: recommendation.sku, isGiftWrapping: this.showGiftWrapping
          });
        } else {
          this.pullRecommendationFromBackend();
        }
      });
    },
    pullRecommendationFromBackend() {
      if (this.showSmallPlants) {
        // use the hard-coded small plants list rather than fetch from backend
        let productList = ['PLANT-1-014-002', 'PLANT-1-013-002', 'PLANT-1-017-006'];
        if (this.showGiftWrapping) {
          productList = ['WRAP-1-001-001'];
        }
        this.$store.dispatch('product/list', {
          query: productList
        }, { root: true }).then((resp) => {
          // make sure the product being recommended is in stock and not in cart
          const productsAvailable = resp?.filter(variant => variant.is_in_stock && !this.productsInCart.find(product => product.id === variant.id));
          const numProductsAvailable = productsAvailable?.length;
          if (numProductsAvailable >= 1) {
            // pick a random product from the ones available
            const randomIndex = numProductsAvailable > 1 ? Math.floor(Math.random() * numProductsAvailable) : 0;
            const recommendedSmallPlant = productsAvailable[randomIndex];
            this.$store.commit('recommendations/setRecommendation', recommendedSmallPlant);
          }
        });
      } else {
        this.$store.dispatch('recommendations/pullRecommendations');
      }
    },
    sendLoading(state) {
      this.$emit('loading', state);
    },
    addRecommendation() {
      /* Hides the recommendation tile, saves the data in localStorage and emits to the
      parent Microcart component, so we can run animations based on that. */
      this.hasAddedRecommendation = true;

      const storedRecommendation = localStorage.getItem('storedRecommendation');
      if (storedRecommendation) {
        const parsedRecommendation = JSON.parse(storedRecommendation);
        parsedRecommendation.hasAddedRecommendation = true;
        const recommendationObjectJSON = JSON.stringify(parsedRecommendation);
        localStorage.setItem('storedRecommendation', recommendationObjectJSON);
        this.sendLoading(true);
        this.emitHideTotal();
      }
    },
    dispatchAddItem(el) {
      /* The actual add to cart action to dispatch to the store is triggered when the
      transition on hiding the recommendation is finished, to provide a smooth running
      animation, as there is total element animation relying on this part in the
      Microcart component */
      const recommendationToAdd = this.recommendedVariant;
      recommendationToAdd.qty = 1;

      setTimeout(() => {
        this.$store.dispatch('cart/addItem', { productToAdd: recommendationToAdd });
      }, 500);

      postTrackEvent('Product Added', {
        cart_id: this.cartToken,
        currency: window.currentCurrency[0],
        price: recommendationToAdd.price,
        product_id: recommendationToAdd.product_id,
        quantity: 1,
        sku: recommendationToAdd.sku,
        add_type: 'Basket recommendation',
        url: window.location.pathname
      });
    },
    emitHideTotal() {
      this.$emit('hideTotal');
    }
  }
};
</script>
