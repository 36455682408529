<template lang="pug">
  .search-recommendation__text
    p.search-recommendation__name {{ text }}
    p.search-recommendation__count {{ count }}
</template>

<script>
export default {
  name: 'SuggestionLine',
  components: {},
  props: {
    text: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>
