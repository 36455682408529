// eslint-disable-next-line no-unused-vars
import Vue from 'vue';
import VueOffline from 'vue-offline';
import './utils/jquery.csrf';
import React from 'react';
import ReactDOM from 'react-dom';
import DevTools from 'mobx-react-devtools';
import { useStrict } from 'mobx';
import 'select2';

import './modules/skipLinks';
import messageStore from './stores/MessageStore';
import Messages from './components/Messages';
import {
  CartAddCollection,
  CartAddVariant,
} from './components/cart';
import Blazy from 'blazy';
import Accordion from 'accordion-js';
import backLinks from './modules/backlink';
import Search from './components/search/Search.vue';
import './moment_config';
import CartWrapper from './themes/CartWrapper.vue';
import CartIcon from './themes/CartIcon.vue';
import { EventBusPlugin } from './core/compatibility/plugins/event-bus';
import VueObserveVisibility from 'vue-observe-visibility';
import { getPrefix } from './utils/prefix';
import { initializeSyncTaskStorage } from './core/lib/sync/task';
import { postTrackEvent } from './utils/analytics';
import navTracking from './modules/nav_tracking';
import Navigation from './components/navigation/Navigation.vue';
import eventListenerPageHelpers from './utils/eventListenerPageHelpers';
import speedCurveLabels from './utils/speedCurve';
import {sentryConfigs} from './utils/sentry';

useStrict(true);

backLinks();

// Vue components setup along with serviceworks
Vue.use(EventBusPlugin);
Vue.use(VueObserveVisibility);
Vue.use(VueOffline);

function dbSetup () {
  if (typeof Vue.prototype.$db === 'undefined') {
    Vue.prototype.$db = {};
  }
  initializeSyncTaskStorage();
  Vue.prototype.$db.currentStoreCode = getPrefix();
}
dbSetup();

// Service worker elements
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(`${globalHostUrl}/sw.js`);
  });
}

(function() {
  const canHover = window.matchMedia('(hover: hover)').matches;
  const blazySelector = canHover ? '.b-lazy, .b-lazy-hover' : '.b-lazy';
  window.bLazy = new Blazy({
    selector: blazySelector,
    offset: 500,
    success: function(el) {
      if (canHover) {
        el.closest('.card__image-container')?.classList.add('b-loaded-hover');
      }
    }
  });

  const messages = document.querySelector('.js-messages');
  if (messages) {
    ReactDOM.render(<Messages store={messageStore} />, messages);
  }

  const devtools = document.querySelector('#js-mobx-debug-tools');
  if (devtools) {
    ReactDOM.render(<DevTools />, devtools);
  }

  // Add from product card
  const jsCardCartAddVariant = document.querySelectorAll('.js-card-cart-add-variant');
  jsCardCartAddVariant.forEach((item, index) => {
    let rawProductData = item.dataset.productData;
    if (item.nextElementSibling) {
      // Check if the next sibling has a specific class
      const hasScriptDataClass = item.nextElementSibling.classList.contains('js-product-data-script');
      if (hasScriptDataClass) {
        rawProductData = item.nextElementSibling.innerHTML;
      }
    }
    const quantity = item.dataset.defaultQuantity;
    if (rawProductData && quantity) {
      const location = item.closest('.js-product-wrap') ? item.closest('.js-product-wrap').dataset.location : '';
      ReactDOM.render(
        <CartAddVariant
          product={JSON.parse(rawProductData)}
          isLarge={false}
          default_quantity={quantity}
          location={location}
          index={index}
        />, item
      );
    }
  });

  const cartElement = document.querySelector('#cartElement');
  if (cartElement) {
    sentryConfigs.vue()
    new Vue({
      el: '#cartElement',
      components: {
        'cart-wrapper': CartWrapper
      }
    });
  }

  const cartIconWrapper = document.querySelector('#cartIconWrapper');
  if (cartIconWrapper) {
    new Vue({
      el: '#cartIconWrapper',
      components: {
        'bag-icon': CartIcon
      }
    });
  }

  // This is the vue store for the cart settup
  // Desktop VUE init first
  const desktopSearch = document.querySelector('#searchContainer');
  if (desktopSearch) {
    new Vue({
      el: '#searchContainer',
      delimiters: ['<%', '%>'],
      components: {
        'search-banner': Search
      },
      data() {
        return {
          show: false,
          noresults: false,
          searchButton: undefined,
          placeholder: 'Search by plant name, plant type or space in your home'
        };
      },
      methods: {
        close() {
          this.toggleClassess();
        },
        toggleClassess() {
          this.searchButton.classList.toggle('is-active');
          this.show = !this.show;
          const body = document.querySelector('body');
          body.classList.toggle('fix-scroll');
          if (this.show) {
            postTrackEvent('Search Start');
            setTimeout(function() {
              // wait for animation
              const searchInput = document.querySelector('.js-search-input');
              searchInput.focus();
              searchInput.select();
            }, 100);
          }
          if (this.show && window.hj !== undefined) {
            window.hj('trigger', 'search-start');
          }
        },
        checkNoResults() {
          if (window.location.search.indexOf('s_results') > -1) {
            this.noresults = true;
            this.toggleClassess();
          } else {
            this.noresults = false;
          }
        }
      },
      mounted() {
        const searchButton = document.querySelector('#searchButton');
        const elem = this;
        if (searchButton) {
          elem.searchButton = searchButton;
          elem.searchButton.addEventListener('click', function() {
            elem.toggleClassess();
          });
        }
        if (window.innerWidth >= 1024) {
          this.checkNoResults();
        }
      }
    });
  }
  // Event to submit on results
  const pastSearch = window.searchDataResultCount;
  if (pastSearch) {
    postTrackEvent('Search Results', {
      term: pastSearch.text,
      count: pastSearch.count
    });
  }

  const topnav = document.getElementById('topnav');
  if (topnav) {
    const navJson = document.getElementById('topnav-structure').innerHTML;
    new Vue({
      el: '#topnav',
      data: {
        structure: JSON.parse(navJson),
        ghiImgPath: topnav.getAttribute('data-ghi-img-path')
      },
      render: h => h(Navigation)
    });
  }

  // collections add to cart
  const jsCardCartAddCollection = document.querySelectorAll('.js-cart-add-collection');
  jsCardCartAddCollection.forEach(item => {
    const productData = item.dataset.collectionProductData;
    if (productData) {
      const collectionName = item.dataset.collectionName;
      const buttonLabelData = item.dataset.collectionButtonLabel;
      const buttonLabel = buttonLabelData || `${PATCH_I18N.cart.add} ${PATCH_I18N.cart.to_bag}`;
      ReactDOM.render(
        <CartAddCollection
          products={JSON.parse(productData)}
          collectionName={collectionName}
          buttonLabel={buttonLabel}
        />,
        item
      );
    }
  });

  const orderConfirmationParcel = document.querySelector('.order-confirmation__accordion-container');
  if (orderConfirmationParcel) {
    new Accordion('.accordion', {
      elementClass: 'accordion-item',
      triggerClass: 'accordion-title',
      panelClass: 'accordion-content',
    });
  }

// END initialization function
})();

navTracking();
eventListenerPageHelpers();
speedCurveLabels();
