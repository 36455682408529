<template>
<div class="topnav-mobile">
  <div class="topnav-mobile-btn-wrap" @click="toggleNav">
    <div class="topnav-mobile-btn" data-testid="mobile-menu-btn"><span></span></div>
  </div>
  <div class="topnav-mobile-overlay" data-testid="mobile-navigation" v-if="isOpen">
    <div class="bg" @click="toggleNav" :class="closeAnimationClass"></div>
    <div class="topnav-mobile-overlay-content" slidemobile-transition="slidemobile-transition" ref="content" :class="closeAnimationClass">
      <div class="topnav-mobile-header">
        <div v-if="levelOne" class="topnav-back-arrow" @click="goBack"></div>
        <a v-else class="header__log patch-logo" href="/"  @click="postTrackEvent('nav_click_home',{})">
          <svg class="patch-logo__img">
            <use xlink:href="#patch-logo-xmas" v-if="isItChristmas"></use>
            <use xlink:href="#patch-logo" v-else></use>
          </svg></a>
        <div class="topnav-mobile-close-btn" @click="toggleNav"></div>
      </div>
      <div class="topnav-mobile-content">
        <ul class="topnav-mobile-main" data-testid="mobile-nav-menu">
          <li class="topnav-mobile-item" v-for="(item, i) in getTopnavMobileMain" :class="listItemClass(item)" :style="getAnimationDelay(i)" :key="item.name + i" @click="trackNavItem(item)">
            <div v-if="item.children || item.weighting === 'H3'" class="item-inner" @click="selectSubNav(item)">{{item.name}}</div>
            <a v-else :href="item.url">{{item.name}}</a>
          </li>
        </ul>
        <ul class="topnav-mobile-secondary">
          <li class="topnav-mobile-item" v-for="(item, i) in getTopnavMobileSecondary" :style="getAnimationDelay(i)" :key="item.name + i" @click="trackNavItem(item)">
            <a :href="item.url">{{item.name}}</a>
          </li>
        </ul>
        <div class="ghi-approved-badge ghi-approved-badge--mobile-nav" :style="getAnimationDelay(3)">
          <img :src="ghiImgPath" class="ghi-logo" alt="Good Housekeeping Institute - Approved 2024"/>
          <div class="ghi-label">
            <p>Best plant delivery service</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div></template>

<script>

import { postTrackEvent } from '../../utils/analytics';

export default {
  name: 'NavigationMobile',
  data() {
    return {
      dataStructure: [],
      isOpen: false,
      levelActive: [],
      levelOne: null,
      levelTwo: null,
      levelThree: null,
      closeAnimation: false,
      animationDelay: false,
      ghiImgPath: '',
    }
  },
  created() {
    this.dataStructure = this.$root.structure;
    this.levelActive = this.dataStructure;
    this.ghiImgPath = this.$root.ghiImgPath;
  },
  computed: {
    closeAnimationClass() {
      return { 'animate-out': this.closeAnimation };
    },
    isItChristmas() {
      return window.xmasDecorations
    },
    listItemClass() {
      return item => {
        return {
          'has-subnav': item.children,
          'title-item': item.weighting === 'H3',
          'mb': item.hide_on_desktop && item.weighting === 'H1'
        }
      };
    },
    getAnimationDelay() {
      return i => {
        const delay = this.animationDelay ? 0.5 : 0;
        return {
          'animation-delay': (delay + (0.08*i)) +'s'
        }
      }
    },
    getTopnavMobileMain(){
      return this.levelActive.filter(item => !item.hide_on_desktop || (item.hide_on_desktop && item.weighting === 'H1'));
    },
    getTopnavMobileSecondary(){
      return this.levelActive.filter(item => item.hide_on_desktop && item.weighting !== 'H1');
    }
  },
  methods: {
    trackNavItem(item) {
      postTrackEvent('nav_click_item', {
        position: 'header',
        name: item.name,
        link: item.url,
        type: 'mobile'
      });
    },
    toggleNav() {
      if (this.isOpen) {
        this.closeAnimation = true;
        this.$refs.content.addEventListener('animationend', () => {
          this.isOpen = false;
        })
        postTrackEvent('nav_close', {type: 'mobile'})
        document.body.style.overflow = 'auto';
      } else {
        this.isOpen = true;
        this.closeAnimation = false;
        this.animationDelay = true;
        document.body.style.overflow = 'hidden';
        postTrackEvent('nav_open', {type: 'mobile'})
      }

      this.levelActive = this.dataStructure;
      this.levelOne = null;
      this.levelTwo = null;
      this.levelThree = null;
    },
    selectSubNav(item) {
      this.animationDelay = false;
      if (!this.levelOne) {
        this.levelOne = item;
        this.updateLevelOneActive(item);
      } else {
        if (!this.levelTwo) {
          this.levelTwo = item;
        } else if (!this.levelThree) {
          this.levelThree = item;
        }
        this.levelActive = item.children
      }
    },
    goBack() {
      postTrackEvent('nav_click_back', {})
      if (this.levelThree) {
        this.levelThree = null;
        this.levelActive = this.levelTwo.children;
      } else if (this.levelTwo) {
        this.levelTwo = null
        this.updateLevelOneActive(this.levelOne);
      } else if (this.levelOne) {
        this.levelOne = null;
        this.levelActive = this.dataStructure;
      }
    },
    updateLevelOneActive(item) {
      let childItems = [];
      childItems.push(...item.children[0].children)
      childItems.unshift({name: item.children[0].name, url: item.children[0].url, hide_on_desktop: item.hide_on_desktop})
      this.levelActive = childItems;
    }
  }
};
</script>
