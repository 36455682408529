<template lang="pug">
  .cart-qty-input__container
    .cart-qty-input__desktop
      button(v-on:click="minusQty" :disabled="belowZeroError" :class="getMinusBtnClass")
        svg(width="13", height="13", viewBox="-5 -5 30 30")
          use(xlink:href="#minus_button")
      input.cart-qty-input__input(
        :key="getInputId"
        type="number"
        :min="min"
        :max="max"
        :value="inputValue"
        v-on:keyup="submitQuantity")
      button(v-on:click="plusQty" :class="getPlusBtnClass" :disabled="outOfStockError")
        svg(width="13", height="13", viewBox="-5 -5 30 30")
          use(xlink:href="#plus_button")
    .cart-qty-input__mobile
      select.cart-qty-input__select(v-model="inputValue" v-on:change="submitQuantity")
        option(v-for="number in 100" :value=number) {{ number }}
</template>

<script>
import { debounce } from './../../../../../utils/eventListenerPageHelpers';
import { postTrackEvent } from './../../../../../utils/analytics';
export default {
  name: 'BaseInput',
  data () {
    return {
      inputValue: 0,
      belowZeroError: false,
    };
  },
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    outOfStockError: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    outOfStockError: {
      immediate: true,
      deep: true,
      handler() {
        this.inputValue = this.outOfStockError ? this.max : this.value;
      }
    },
    value: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        if (oldVal === 0) {
          this.$emit('input', val);
        }
        this.inputValue = val;
      }
    }

  },
  computed: {
    getInputId () {
      return `input_${this._uid}`;
    },
    getPlusBtnClass () {
      return {
        'cart-qty-input__has-error': this.outOfStockError,
        'cart-qty-input__plus': true,
      };
    },
    getMinusBtnClass () {
      return {
        'cart-qty-input__has-error': this.belowZeroError,
        'cart-qty-input__minus': true,
      };
    }
  },
  beforeMount() {    
    this.submitQuantity = debounce(this.submitQuantity, 1500);
  },
  methods: {
    submitQuantity (e) {
      if (e.target.value) {
        this.inputValue = parseInt(e.target.value);
        this.$emit('loading', true);
        this.$emit('input', this.inputValue);
      } else {
        this.inputValue = this.value;
      }
      if (this.inputValue > this.max){
        this.inputValue = this.max;
      }
    },
    minusQty() {
      if (this.inputValue > 0) {
        postTrackEvent('Item qty decreased', {product_id: this.$attrs["data-id"]});
        this.inputValue = this.inputValue - 1;
        this.$emit('loading', true);
        this.$emit('input', this.inputValue);

        if (this.inputValue === 0) {
          this.belowZeroError = true;
          this.inputValue = 1;
          this.$emit('loading', false);
        } 
      }
    },
    plusQty() {
      postTrackEvent('Product Added', {
        cart_id: this.$store.getters['cart/getCartToken'],
        currency: window.currentCurrency[0],
        price: this.$attrs["data-price"],
        product_id: this.$attrs["data-id"],
        quantity: this.inputValue,
        sku: this.$attrs["data-sku"],
        add_type: 'Quantity picker in basket',
        url: window.location.pathname
      });
      this.$emit('loading', true);
      this.belowZeroError = false;
      this.inputValue = this.inputValue + 1;
      this.$emit('input', this.inputValue);
      if (this.inputValue > this.max){
        this.inputValue = this.max;
      }
    },
  },
  mounted () {
    this.inputValue = this.value;
  }
};
</script>
