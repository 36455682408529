import rootStore from '../store';
import {StoreView} from './types';

export function currentStoreView(): StoreView {
  // note: Change to getter all along our code
  return rootStore.state.storeView;
}

export function adjustMultistoreApiUrl(url: string): string {
  const storeView = currentStoreView();
  if (storeView.storeCode) {
    const urlSep = (url.indexOf('?') > 0) ? '&' : '?';
    url += urlSep + 'storeCode=' + storeView.storeCode;
  }
  return url;
}

export function setupMultistoreRoutes(config, router: any, routes: any[]): void {
  console.log(config, router, routes);
}
