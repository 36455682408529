import * as Sentry from '@sentry/vue';
import Vue from 'vue';

const initSentry = function(forVue) {
  const sentryDsn = process.env.SENTRY_DSN_FRONTEND;
  const sentryEnv = process.env.SENTRY_ENV;
  if (sentryDsn && sentryEnv) {
    const opts = {
      dsn: sentryDsn,
      environment: sentryEnv,
      integrations: function(integrations) {
        return integrations.filter(function(integration) {
          return !['InboundFilters'].includes(integration.name);
        });
      },
      ignoreErrors: [/^Script error\.$/]
    };
    if (forVue) opts.Vue = Vue;
    Sentry.init(opts);
    const tag = forVue ? 'vue' : 'js';
    Sentry.setTag('sentry_config_key', tag);
  }
};

export const sentryConfigs = {
  vue: function() {
    initSentry(true);
  },
  js: function() {
    initSentry(false);
  }
};
