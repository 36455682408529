<template lang="pug">
  .column.small-12.medium-6.countrypicker
    .picker-menu(v-show="menuOpen" v-bind:class="{'active': menuOpen}")
      .picker-menu-arrow
      ul
        li(v-for="item in choices" @click="selectCountry(item)").picker-item
          span.countryflag(v-bind:class="flag(item[0])")
          span.text(v-html="item[2]")
    .picker-wrapper(@click="toggleMenu")
      .picker-icon
        .countryflag(v-bind:class="country")
      .picker-arrow(v-bind:class="arrowDirection")
</template>

<script>

export default {
  name: 'CountryPicker',
  components: {},
  props: {
    'original': {
      type: String,
      default: 'gb'
    },
    'choices': {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  data: function () {
    return {
      menuOpen: false
    };
  },
  computed: {
    country() {
      if (this.original) {
        return this.flag(this.original);
      }
    },
    arrowDirection() {
      if (this.menuOpen) {
        return 'up';
      }
      return 'down';
    }
  },
  watch: {},
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    flag(value) {
      if (value) {
        return 'flag-' + value.toLowerCase();
      }
    },
    selectCountry(item) {
      this.$emit('countryset', item);
      this.toggleMenu();
    }
  },
};
</script>
